export const SET_MSAL_INSTANCE = 'SET_MSAL_INSTANCE';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_ID_TOKEN = 'SET_ID_TOKEN';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const LOAD_DEFAULT_FDR_TEMPLATES_SUCCESS = 'LOAD_DEFAULT_FDR_TEMPLATES_SUCCESS';
export const LOAD_DEFAULT_FDR_TEMPLATES_FAILURE = 'LOAD_DEFAULT_FDR_TEMPLATES_FAILURE';
export const LOAD_CHARTS_SUCCESS = 'LOAD_CHARTS_SUCCESS';
export const LOAD_CHARTS_FAILURE = 'LOAD_CHARTS_FAILURE';
export const LOAD_CHART_OF_ACCOUNTS_SUCCESS = 'LOAD_CHART_OF_ACCOUNTS_SUCCESS';
export const SET_CHART_DUPLICATE = 'SET_CHART_DUPLICATE';
export const SET_CHARTOFACCOUNT_DUPLICATE = 'SET_CHARTOFACCOUNT_DUPLICATE';
export const CLEAR_CHART_DUPLICATE = 'CLEAR_CHART_DUPLICATE';
export const CLEAR_CHARTOFACCOUNT_DUPLICATE = 'CLEAR_CHARTOFACCOUNT_DUPLICATE';
export const CREATE_CHART_FAILURE = 'CREATE_CHART_FAILURE';
export const CREATE_CHARTOFACCOUNT_FAILURE = 'CREATE_CHARTOFACCOUNT_FAILURE';
export const LOAD_CALENDAR_YEARS_FAILURE = 'LOAD_CALENDAR_YEARS_FAILURE';
export const LOAD_CHART_ACCOUNT_MAPPINGS_FAILURE = 'LOAD_CHART_ACCOUNT_MAPPINGS_FAILURE';
export const LOAD_CALENDAR_YEAR_REITS_FAILURE = 'LOAD_CALENDAR_YEAR_REITS_FAILURE';
export const DELETE_QUESTIONNAIRE_TEMPLATE_FAILURE = 'DELETE_QUESTIONNAIRE_TEMPLATE_FAILURE';
export const CREATE_QUESTIONNAIRE_TEMPLATE_FAILURE = 'CREATE_QUESTIONNAIRE_TEMPLATE_FAILURE';
export const CREATE_CUSTOM_FDR_TEMPLATE_FAILURE = 'CREATE_CUSTOM_FDR_TEMPLATE_FAILURE';
export const CREATE_CUSTOM_FDR_TEMPLATE_SUCCESS = 'CREATE_CUSTOM_FDR_TEMPLATE_SUCCESS';
export const LOAD_CUSTOM_FDR_TEMPLATES_SUCCESS = 'LOAD_CUSTOM_FDR_TEMPLATES_SUCCESS';
export const LOAD_CUSTOM_FDR_TEMPLATES_FAILURE = 'LOAD_CUSTOM_FDR_TEMPLATES_FAILURE';
export const CLEAR_CUSTOM_FDR_TEMPLATE_DUPLICATE = 'CLEAR_CUSTOM_FDR_TEMPLATE_DUPLICATE';
export const SAVE_DEFAULT_FDR_TEMPLATES_FAILURE = 'SAVE_DEFAULT_FDR_TEMPLATES_FAILURE';
export const SAVE_DEFAULT_FDR_TEMPLATES_SUCCESS = 'SAVE_DEFAULT_FDR_TEMPLATES_SUCCESS';
export const SET_CUSTOM_FDR_TEMPLATE_DUPLICATE = 'SET_CUSTOM_FDR_TEMPLATE_DUPLICATE';
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const UPDATE_CUSTOM_FDR_TEMPLATES_SUCCESS = 'UPDATE_CUSTOM_FDR_TEMPLATES_SUCCESS';
export const SAVE_CUSTOM_QUESTIONNAIRE_TEMPLATE_FAILURE = 'SAVE_CUSTOM_QUESTIONNAIRE_TEMPLATE_FAILURE';
export const LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATE_QUESTIONS_SUCCESS = 'LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATE_QUESTIONS_SUCCESS';
export const LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATE_QUESTIONS_FAILURE = 'LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATE_QUESTIONS_FAILURE';
export const LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATES_SUCCESS = 'LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATES_SUCCESS';
export const LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATES_FAILURE = 'LOAD_CUSTOM_QUESTIONNAIRE_TEMPLATES_FAILURE';
export const LOAD_PSQ_TEMPLATES_SUCCESS = 'LOAD_PSQ_TEMPLATES_SUCCESS';
export const LOAD_PSQ_TEMPLATES_FAILURE = 'LOAD_PSQ_TEMPLATES_FAILURE';
export const LOAD_BULK_REPORT_PACKAGES_SUCCESS = 'LOAD_BULK_REPORT_PACKAGES_SUCCESS';
export const LOAD_BULK_REPORT_PACKAGES_FAILURE = 'LOAD_BULK_REPORT_PACKAGES_FAILURE';
export const LOAD_BULK_REPORT_PACKAGES_ETA_SUCCESS = 'LOAD_BULK_REPORT_PACKAGES_ETA_SUCCESS';
export const LOAD_BULK_REPORT_PACKAGES_ETA_FAILURE = 'LOAD_BULK_REPORT_PACKAGES_ETA_FAILURE';
export const UPDATE_BULK_REPORT_PACKAGE_ETA = 'UPDATE_BULK_REPORT_PACKAGE_ETA';
export const RECEIVE_BULK_REPORT_PACKAGE_MESSAGE = 'RECEIVE_BULK_REPORT_PACKAGE_MESSAGE';
export const CREATE_BULK_REPORT_PACKAGES_SUCCESS = 'CREATE_BULK_REPORT_PACKAGES_SUCCESS';
export const CREATE_BULK_REPORT_PACKAGES_FAILURE = 'CREATE_BULK_REPORT_PACKAGES_FAILURE';
export const DELETE_BULK_REPORT_PACKAGES_FAILURE = 'DELETE_BULK_REPORT_PACKAGES_FAILURE';
export const SET_AUTHENTICATION_SCOPE = 'SET_AUTHENTICATION_SCOPE';
export const SAVE_CHECKLIST_DELIVERY_SCHEDULE_FAILURE = 'SAVE_CHECKLIST_DELIVERY_SCHEDULE_FAILURE';
export const LOAD_CHECKLIST_DELIVERY_SCHEDULE_SUCCESS = 'LOAD_CHECKLIST_DELIVERY_SCHEDULE_SUCCESS';
export const LOAD_CHECKLIST_DELIVERY_SCHEDULE_FAILURE = 'LOAD_CHECKLIST_DELIVERY_SCHEDULE_FAILURE';
export const LOAD_CHECKLIST_CALENDAR_YEARS_SUCCESS = 'LOAD_CHECKLIST_CALENDAR_YEARS_SUCCESS';
export const LOAD_CHECKLIST_CALENDAR_YEARS_FAILURE = 'LOAD_CHECKLIST_CALENDAR_YEARS_FAILURE';
export const LOAD_CHECKLIST_CALENDAR_YEARS_BY_CLIENT_AND_TYPE_SUCCESS = 'LOAD_CHECKLIST_CALENDAR_YEARS_BY_CLIENT_AND_TYPE_SUCCESS';
export const LOAD_CHECKLIST_CALENDAR_YEARS_BY_CLIENT_AND_TYPE_FAILURE = 'LOAD_CHECKLIST_CALENDAR_YEARS_BY_CLIENT_AND_TYPE_FAILURE';
export const RESET_CHECKLIST_DELIVERY_SCHEDULE_SUCCESS = 'RESET_CHECKLIST_DELIVERY_SCHEDULE_SUCCESS';
export const RESET_CHECKLIST_DELIVERY_SCHEDULE_FAILURE = 'RESET_CHECKLIST_DELIVERY_SCHEDULE_FAILURE';
export const LAST_CLICKED_TAB_CHECKLIST_QUESTION_ID = 'LAST_CLICKED_TAB_CHECKLIST_QUESTION_ID';
export const DELETE_CHECKLIST_ATTACHMENT_SUCCESS = 'DELETE_CHECKLIST_ATTACHMENT_SUCCESS';
export const DELETE_CHECKLIST_ATTACHMENT_FAILURE = 'DELETE_CHECKLIST_ATTACHMENT_FAILURE';
export const LOAD_CHECKLIST_FILES_SUCCESS = 'LOAD_CHECKLIST_FILES_SUCCESS';
export const LOAD_CHECKLIST_FILES_FAILURE = 'LOAD_CHECKLIST_FILES_FAILURE';
export const ALLOW_UPDATE__REPORT_PERIOD_VISIBILITY_CONSTRAINT_SUCCESS = 'ALLOW_UPDATE__REPORT_PERIOD_VISIBILITY_CONSTRAINT_SUCCESS';
export const ALLOW_UPDATE__REPORT_PERIOD_VISIBILITY_CONSTRAINT_FAILURE = 'ALLOW_UPDATE__REPORT_PERIOD_VISIBILITY_CONSTRAINT_FAILURE';
export const LOAD_EXTERNAL_CHECKLIST_LIST_SUCCESS = 'LOAD_EXTERNAL_CHECKLIST_LIST_SUCCESS';
export const LOAD_EXTERNAL_CHECKLIST_LIST_FAILURE = 'LOAD_EXTERNAL_CHECKLIST_LIST_FAILURE';
export const SAVE_NEW_CHECKLIST_COMMENT_SUCCESS = 'SAVE_NEW_CHECKLIST_COMMENT_SUCCESS';
export const SAVE_NEW_CHECKLIST_COMMENT_FAILURE = 'SAVE_NEW_CHECKLIST_COMMENT_FAILURE';
export const CLEAR_ERROR_RESPONSE = 'CLEAR_ERROR_RESPONSE';
export const CLEAR_USERS_MESSAGE = 'CLEAR_USERS_MESSAGE';
export const CREATE_CHECKLIST_FAILURE = 'CREATE_CHECKLIST_FAILURE';
export const CREATE_CHECKLIST_SUCCESS = 'CREATE_CHECKLIST_SUCCESS';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAILURE = 'CREATE_CLIENT_FAILURE';
export const CREATE_DISTRIBUTION_SUCCESS = 'CREATE_DISTRIBUTION_SUCCESS';
export const CREATE_DISTRIBUTION_FAILURE = 'CREATE_DISTRIBUTION_FAILURE';
export const CREATE_DISTRIBUTION_TAXABLE_INCOME_SUCCESS =
  'CREATE_DISTRIBUTION_TAXABLE_INCOME_SUCCESS';
export const CREATE_DISTRIBUTION_TAXABLE_INCOME_FAILURE =
  'CREATE_DISTRIBUTION_TAXABLE_INCOME_FAILURE';
export const CREATE_SECURITIES_SNAPSHOT_SUCCESS =
  'CREATE_SECURITIES_SNAPSHOT_SUCCESS';
export const CREATE_SECURITIES_SNAPSHOT_FAILURE =
  'CREATE_SECURITIES_SNAPSHOT_FAILURE';
export const CREATE_SERVICES_SUCCESS = 'CREATE_SERVICES_SUCCESS';
export const CREATE_SERVICES_FAILURE = 'CREATE_SERVICES_FAILURE';
export const DELETE_CHECKLIST_CLEAR = 'DELETE_CHECKLIST_CLEAR';
export const DELETE_CHECKLIST_FAILURE = 'DELETE_CHECKLIST_FAILURE';
export const DELETE_CHECKLIST_SUCCESS = 'DELETE_CHECKLIST_SUCCESS';
export const DELETE_DISTRIBUTION_SUCCESS = 'DELETE_DISTRIBUTION_SUCCESS';
export const DELETE_DISTRIBUTION_FAILURE = 'DELETE_DISTRIBUTION_FAILURE';
export const DELETE_SECURITIES_SNAPSHOT_SUCCESS =
  'DELETE_SECURITIES_SNAPSHOT_SUCCESS';
export const DELETE_SECURITIES_SNAPSHOT_FAILURE =
  'DELETE_SECURITIES_SNAPSHOT_FAILURE';
export const DELETE_TRS_SUCCESS = 'DELETE_TRS_SUCCESS';
export const DELETE_TRS_FAILURE = 'DELETE_TRS_FAILURE';
export const ERROR = 'ERROR';
export const LOAD_ALL_REITS_FAILURE = 'LOAD_ALL_REITS_FAILURE';
export const LOAD_ALL_RIETS_SUCCESS = 'LOAD_ALL_RIETS_SUCCESS';
export const LOAD_ARQC_CHECKLIST_SUCCESS = 'LOAD_ARQC_CHECKLIST_SUCCESS';
export const LOAD_ARQC_CHECKLIST_FAILURE = 'LOAD_ARQC_CHECKLIST_FAILURE';
export const LOAD_ASSET_DETAIL_SUCCESS = 'LOAD_ASSET_DETAIL_SUCCESS';
export const LOAD_ASSET_DETAIL_FAILURE = 'LOAD_ASSET_DETAIL_FAILURE';
export const LOAD_ASSET_DETAILS_FOR_YEAR_FAILURE =
  'LOAD_ASSET_DETAILS_FOR_YEAR_FAILURE';
export const LOAD_ASSET_DETAILS_FOR_YEAR_SUCCESS =
  'LOAD_ASSET_DETAILS_FOR_YEAR_SUCCESS';
export const LOAD_ASSET_SUMMARY_SUCCESS = 'LOAD_ASSET_SUMMARY_SUCCESS';
export const LOAD_ASSET_SUMMARY_FAILURE = 'LOAD_ASSET_SUMMARY_FAILURE';
export const LOAD_CLIENT_FAILURE = 'LOAD_CLIENT_FAILURE';
export const LOAD_CLIENT_SUCCESS = 'LOAD_CLIENT_SUCCESS';
export const LOAD_CLIENTS_FAILURE = 'LOAD_CLIENTS_FAILURE';
export const LOAD_CLIENTS_SUCCESS = 'LOAD_CLIENTS_SUCCESS';

export const LOAD_EXTERNAL_CHECKLISTS_CLIENTS_SUCCESS = 'LOAD_EXTERNAL_CHECKLISTS_CLIENTS_SUCCESS';
export const LOAD_EXTERNAL_CHECKLISTS_CLIENTS_FAILURE = 'LOAD_EXTERNAL_CHECKLISTS_CLIENTS_FAILURE';
export const LOAD_CLIENT_ACCOUNT_DATA_SUCCESS =
  'LOAD_CLIENT_ACCOUNT_DATA_SUCCESS';
export const LOAD_CLIENT_ACCOUNT_DATA_FAILURE =
  'LOAD_CLIENT_ACCOUNT_DATA_FAILURE';
export const LOAD_COA_SUCCESS = 'LOAD_COA_SUCCESS';
export const LOAD_COA_FAILURE = 'LOAD_COA_FAILURE';
export const LOAD_CHART_MAPPINGS_SUCCESS = 'LOAD_CHART_MAPPINGS_SUCCESS';
export const LOAD_CHART_MAPPINGS_FAILURE = 'LOAD_CHART_MAPPINGS_FAILURE';
export const LOAD_COA_DETAIL_GROUP_MAPPINGS_SUCCESS =
  'LOAD_COA_DETAIL_GROUP_MAPPINGS_SUCCESS';
export const LOAD_COA_DETAIL_GROUP_MAPPINGS_FAILURE =
  'LOAD_COA_DETAIL_GROUP_MAPPINGS_FAILURE';
export const LOAD_DETAIL_GROUPS_MAPPINGS_SUCCESS =
  'LOAD_DETAIL_GROUPS_MAPPINGS_SUCCESS';
export const LOAD_DETAIL_GROUPS_MAPPINGS_FAILURE =
  'LOAD_DETAIL_GROUPS_MAPPINGS_FAILURE';
export const LOAD_DISTRIBUTION_DETAIL_SUCCESS =
  'LOAD_DISTRIBUTION_DETAIL_SUCCESS';
export const LOAD_DISTRIBUTION_DETAIL_FAILURE =
  'LOAD_DISTRIBUTION_DETAIL_FAILURE';
export const LOAD_DISTRIBUTION_TAXABLE_INCOME_SUCCESS =
  'LOAD_DISTRIBUTION_TAXABLE_INCOME_SUCCESS';
export const LOAD_DISTRIBUTION_TAXABLE_INCOME_FAILURE =
  'LOAD_DISTRIBUTION_TAXABLE_INCOME_FAILURE';
export const LOAD_DISTRIBUTION_TEST_SUMMARY_SUCCESS =
  'LOAD_DISTRIBUTION_TEST_SUMMARY_SUCCESS';
export const LOAD_DISTRIBUTION_TEST_SUMMARY_FAILURE =
  'LOAD_DISTRIBUTION_TEST_SUMMARY_FAILURE';
export const LOAD_EXTERNAL_ARQC_SUCCESS = 'LOAD_EXTERNAL_ARQC_SUCCESS';
export const LOAD_EXTERNAL_ARQC_FAILURE = 'LOAD_EXTERNAL_ARQC_FAILURE';
export const LOAD_EXTERNAL_PSQ_SUCCESS = 'LOAD_EXTERNAL_PSQ_SUCCESS';
export const LOAD_EXTERNAL_PSQ_FAILURE = 'LOAD_EXTERNAL_PSQ_FAILURE';
export const EXTERNAL_ARQC_EXCLUDE_UPDATING_CURRENT_CHECKLIST_SUCCESS = 'EXTERNAL_ARQC_EXCLUDE_UPDATING_CURRENT_CHECKLIST_SUCCESS';
export const EXTERNAL_PSQ_EXCLUDE_UPDATING_CURRENT_CHECKLIST_SUCCESS = 'EXTERNAL_PSQ_EXCLUDE_UPDATING_CURRENT_CHECKLIST_SUCCESS';

export const LOAD_FILES_FAILURE = 'LOAD_FILES_FAILURE';
export const LOAD_FILES_SUCCESS = 'LOAD_FILES_SUCCESS';
export const LOAD_FINAL_REPORT_FAILURE = 'LOAD_FINAL_REPORT_FAILURE';
export const LOAD_FINAL_REPORT_SUCCESS = 'LOAD_FINAL_REPORT_SUCCESS';
export const LOAD_LAST_TRIAL_BALANCE_FAILURE =
  'LOAD_LAST_TRIAL_BALANCE_FAILURE';
export const LOAD_LAST_TRIAL_BALANCE_SUCCESS =
  'LOAD_LAST_TRIAL_BALANCE_SUCCESS';
export const LOAD_LAST_PROPERTY_TRIAL_BALANCE_FAILURE =
  'LOAD_LAST_PROPERTY_TRIAL_BALANCE_FAILURE';
export const LOAD_LAST_PROPERTY_TRIAL_BALANCE_SUCCESS =
  'LOAD_LAST_PROPERTY_TRIAL_BALANCE_SUCCESS';
export const LOAD_LAST_REIT_PROPERTY_TRIAL_BALANCE_SUCCESS =
  'LOAD_LAST_REIT_PROPERTY_TRIAL_BALANCE_SUCCESS';
export const LOAD_LAST_REIT_PROPERTY_TRIAL_BALANCE_FAILURE =
  'LOAD_LAST_REIT_PROPERTY_TRIAL_BALANCE_FAILURE';
export const LOAD_LAST_CONSOLIDATED_PROPERTY_TRIAL_BALANCE_SUCCESS =
  'LOAD_LAST_CONSOLIDATED_PROPERTY_TRIAL_BALANCE_SUCCESS';
export const LOAD_LAST_CONSOLIDATED_PROPERTY_TRIAL_BALANCE_FAILURE =
  'LOAD_LAST_CONSOLIDATED_PROPERTY_TRIAL_BALANCE_FAILURE';
export const LOAD_INCOME_DETAIL_SUCCESS = 'LOAD_INCOME_DETAIL_SUCCESS';
export const LOAD_PROPERTY_DETAIL_SUCCESS = 'LOAD_PROPERTY_DETAIL_SUCCESS';
export const LOAD_PROPERTY_DETAIL_FAILURE = 'LOAD_PROPERTY_DETAIL_FAILURE';
export const DELETE_PROPERTY_FAILURE = 'DELETE_PROPERTY_FAILURE';
export const DELETE_PROPERTY_SUCCESS = 'DELETE_PROPERTY_SUCCESS';
export const LOAD_INCOME_DETAIL_FAILURE = 'LOAD_INCOME_DETAIL_FAILURE';
export const LOAD_INCOME_TEST_SUMMARY_SUCCESS =
  'LOAD_INCOME_TEST_SUMMARY_SUCCESS';
export const LOAD_INCOME_TEST_SUMMARY_FAILURE =
  'LOAD_INCOME_TEST_SUMMARY_FAILURE';
export const LOAD_MAPPING_SUCCESS = 'LOAD_MAPPING_SUCCESS';
export const LOAD_MAPPING_FAILURE = 'LOAD_MAPPING_FAILURE';
export const LOAD_PERIOD_SUCCESS = 'LOAD_PERIOD_SUCCESS';
export const LOAD_PERIOD_FAILURE = 'LOAD_PERIOD_FAILURE';
export const LOAD_PERIOD_MAPPINGS_SUCCESS = 'LOAD_PERIOD_MAPPINGS_SUCCESS';
export const LOAD_PERIOD_MAPPINGS_FAILURE = 'LOAD_PERIOD_MAPPINGS_FAILURE';
export const LOAD_PERIODS_BY_CLIENT_ID_SUCCESS = 'LOAD_PERIODS_BY_CLIENT_ID_SUCCESS';
export const LOAD_PERIODS_BY_CLIENT_ID_FAILURE = 'LOAD_PERIODS_BY_CLIENT_ID_FAILURE';
export const LOAD_PERIOD_MAPPINGS_BALANCES_SUCCESS =
  'LOAD_PERIOD_MAPPINGS_BALANCES_SUCCESS';
export const LOAD_PERIOD_MAPPINGS_BALANCES_FAILURE =
  'LOAD_PERIOD_MAPPINGS_BALANCES_FAILURE';
export const LOAD_PREVIOUS_ARQC_CHECKLIST_FAILURE =
  'LOAD_PREVIOUS_ARQC_CHECKLIST_FAILURE';
export const LOAD_PREVIOUS_ARQC_CHECKLIST_SUCCESS =
  'LOAD_PREVIOUS_ARQC_CHECKLIST_SUCCESS';
export const LOAD_PREVIOUS_PSQ_CHECKLIST_FAILURE =
  'LOAD_PREVIOUS_PSQ_CHECKLIST_FAILURE';
export const LOAD_PREVIOUS_PSQ_CHECKLIST_SUCCESS =
  'LOAD_PREVIOUS_PSQ_CHECKLIST_SUCCESS';
export const LOAD_PROPERTIES_SUCCESS = 'LOAD_PROPERTIES_SUCCESS';
export const LOAD_PROPERTIES_FAILURE = 'LOAD_PROPERTIES_FAILURE';
export const LOAD_PROPERTY_SUCCESS = 'LOAD_PROPERTY_SUCCESS';
export const LOAD_PROPERTY_FAILURE = 'LOAD_PROPERTY_FAILURE';
export const LOAD_PROPERTY_ITSI_SUCCESS = 'LOAD_PROPERTY_ITSI_SUCCESS';
export const LOAD_PROPERTY_ITSI_FAILURE = 'LOAD_PROPERTY_ITSI_FAILURE';
export const LOAD_PSQ_CHECKLIST_SUCCESS = 'LOAD_PSQ_CHECKLIST_SUCCESS';
export const LOAD_PSQ_CHECKLIST_FAILURE = 'LOAD_PSQ_CHECKLIST_FAILURE';
export const LOAD_REIT_FAILURE = 'LOAD_REIT_FAILURE';
export const LOAD_RIET_SUCCESS = 'LOAD_RIET_SUCCESS';
export const LOAD_REITS_FAILURE = 'LOAD_REITS_FAILURE';
export const LOAD_REITS_SUCCESS = 'LOAD_REITS_SUCCESS';
export const LOAD_REITS_EXTERNAL_FAILURE = 'LOAD_REITS_EXTERNAL_FAILURE';
export const LOAD_REITS_EXTERNAL_SUCCESS = 'LOAD_REITS_EXTERNAL_SUCCESS';
export const LOAD_REITS_FOR_MO_FAILURE = 'LOAD_REITS_FOR_MO_FAILURE';
export const LOAD_REITS_FOR_MO_SUCCESS = 'LOAD_REITS_FOR_MO_SUCCESS';
export const LOAD_REPORT_ITEMS_FAILURE = 'LOAD_REPORT_ITEMS_FAILURE';
export const LOAD_REPORT_ITEMS_SUCCESS = 'LOAD_REPORT_ITEMS_SUCCESS';
export const LOAD_REPORT_ITEMS_FOR_CLIENT_FAILURE = 'LOAD_REPORT_ITEMS_FOR_CLIENT_FAILURE';
export const LOAD_REPORT_ITEMS_FOR_CLIENT_SUCCESS = 'LOAD_REPORT_ITEMS_FOR_CLIENT_SUCCESS';
export const LOAD_SECURITIES_SNAPSHOTS_FAILURE ='LOAD_SECURITIES_SNAPSHOTS_FAILURE';
export const LOAD_SECURITIES_SNAPSHOTS_SUCCESS ='LOAD_SECURITIES_SNAPSHOTS_SUCCESS';
export const GET_INCOMPLETE_SECURITIES_SNAPSHOTS_FAILURE ='GET_INCOMPLETE_SECURITIES_SNAPSHOTS_FAILURE';
export const GET_INCOMPLETE_SECURITIES_SNAPSHOTS_SUCCESS ='GET_INCOMPLETE_SECURITIES_SNAPSHOTS_SUCCESS';
export const RELOAD_INCOMPLETE_SECURITIES_SNAPSHOTS_SUCCESS ='RELOAD_INCOMPLETE_SECURITIES_SNAPSHOTS_SUCCESS';
export const LOAD_TB_SUMMARY_SUCCESS = 'LOAD_TB_SUMMARY_SUCCESS';
export const LOAD_TB_SUMMARY_FAILURE = 'LOAD_TB_SUMMARY_FAILURE';
export const LOAD_SERVICES_SUCCESS = 'LOAD_SERVICES_SUCCESS';
export const LOAD_SERVICES_FAILURE = 'LOAD_SERVICES_FAILURE';
export const LOAD_NAVIGATION_SERVICES_SUCCESS =
  'LOAD_NAVIGATION_SERVICES_SUCCESS';
export const LOAD_NAVIGATION_SERVICES_FAILURE =
  'LOAD_NAVIGATION_SERVICES_FAILURE';
export const LOAD_AVAILABLE_SERVICES_SUCCESS =
  'LOAD_AVAILABLE_SERVICES_SUCCESS';
export const LOAD_AVAILABLE_SERVICES_FAILURE =
  'LOAD_AVAILABLE_SERVICES_FAILURE';
export const LOAD_TEST_SNAPSHOTS_FAILURE = 'LOAD_TEST_SNAPSHOTS_FAILURE';
export const LOAD_TEST_SNAPSHOTS_SUCCESS = 'LOAD_TEST_SNAPSHOTS_SUCCESS';
export const LOAD_TRSES_FAILURE = 'LOAD_TRSES_FAILURE';
export const LOAD_TRSES_SUCCESS = 'LOAD_TRSES_SUCCESS';
export const LOAD_TB_ADJUSTMENTS_FAILURE = 'LOAD_TB_ADJUSTMENTS_FAILURE';
export const LOAD_TB_ADJUSTMENTS_SUCCESS = 'LOAD_TB_ADJUSTMENTS_SUCCESS';
export const LOAD_TB_ITEM_FAILURE = 'LOAD_TB_ITEM_FAILURE';
export const LOAD_TB_ITEM_SUCCESS = 'LOAD_TB_ITEM_SUCCESS';
export const LOAD_TB_ITEMS_FAILURE = 'LOAD_TB_ITEMS_FAILURE';
export const LOAD_TB_ITEMS_SUCCESS = 'LOAD_TB_ITEMS_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const REPORT_ITEM_SELECTION_TOGGLE = 'REPORT_ITEM_SELECTION_TOGGLE';
export const SET_ARQC_ACTIVE_CHECKLIST_SUCCESS =
  'SET_ARQC_ACTIVE_CHECKLIST_SUCCESS';
export const SET_PSQ_ACTIVE_CHECKLIST_SUCCESS =
  'SET_PSQ_ACTIVE_CHECKLIST_SUCCESS';
export const TASK_BEGIN = 'TASK_BEGIN';
export const TASK_END = 'TASK_END';
export const UPDATE_PENDING_TASKS = 'UPDATE_PENDING_TASKS';
export const TOGGLE_CLIENT_ACTIVE_FLAG_FAILURE =
  'TOGGLE_CLIENT_ACTIVE_FLAG_FAILURE';
export const TOGGLE_REIT_ACTIVE_FLAG_FAILURE =
  'TOGGLE_REIT_ACTIVE_FLAG_FAILURE';
export const UPDATE_CHECKLIST_SUCCESS = 'UPDATE_CHECKLIST_SUCCESS';
export const UPDATE_CHECKLIST_FAILURE = 'UPDATE_CHECKLIST_FAILURE';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE';
export const UPDATE_DISTRIBUTION_SUCCESS = 'UPDATE_DISTRIBUTION_SUCCESS';
export const UPDATE_DISTRIBUTION_FAILURE = 'UPDATE_DISTRIBUTION_FAILURE';
export const UPDATE_PERIOD_STATUS_SUCCESS = 'UPDATE_PERIOD_STATUS_SUCCESS';
export const UPDATE_PERIOD_STATUS_FAILURE = 'UPDATE_PERIOD_STATUS_FAILURE';
export const UPDATE_PROPERTY_SUCCESS = 'UPDATE_PROPERTY_SUCCESS';
export const UPDATE_PROPERTY_FAILURE = 'UPDATE_PROPERTY_FAILURE';
export const DELETE_PROPERTY_DETAIL_FAILURE = 'DELETE_PROPERTY_DETAIL_FAILURE';
export const DELETE_PROPERTY_DETAIL_SUCCESS = 'DELETE_PROPERTY_DETAIL_SUCCESS';
export const UPDATE_SECURITIES_SNAPSHOT_SUCCESS =
  'UPDATE_SECURITIES_SNAPSHOT_SUCCESS';
export const UPDATE_SECURITIES_SNAPSHOT_FAILURE =
  'UPDATE_SECURITIES_SNAPSHOT_FAILURE';
export const UPDATE_TRS_SUCCESS = 'UPDATE_TRS_SUCCESS';
export const UPDATE_TRS_FAILURE = 'UPDATE_TRS_FAILURE';
export const UPLOAD_FILE_CLEAR = 'UPLOAD_FILE_CLEAR';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FINAL_REPORT_CLEAR = 'UPLOAD_FINAL_REPORT_CLEAR';
export const UPLOAD_FINAL_REPORT_FAILURE = 'UPLOAD_FINAL_REPORT_FAILURE';
export const UPLOAD_FINAL_REPORT_SUCCESS = 'UPLOAD_FINAL_REPORT_SUCCESS';
export const UPLOAD_TB_CLEAR = 'UPLOAD_TB_CLEAR';
export const UPLOAD_TB_FAILURE = 'UPLOAD_TB_FAILURE';
export const UPLOAD_TB_SUCCESS = 'UPLOAD_TB_SUCCESS';
export const UNLOAD_CLIENT = 'UNLOAD_CLIENT';
export const UNLOAD_REIT = 'UNLOAD_REIT';
export const UNLOAD_PERIOD = 'UNLOAD_PERIOD';
export const UNLOAD_PROPERTY = 'UNLOAD_PROPERTY';
export const UNLOAD_TESTS = 'UNLOAD_TESTS';
export const UNLOAD_FILE_MANAGEMENT = 'UNLOAD_FILE_MANAGEMENT';
export const UNLOAD_SERVICES = 'UNLOAD_SERVICES';
export const UNLOAD_NAVIGATION_SERVICES = 'UNLOAD_NAVIGATION_SERVICES';

// AUTH - TODO - Put in alphabetical order
export const LOAD_AUTHORIZATION_RESOURCE_SUCCESS =
  'LOAD_AUTHORIZATION_RESOURCE_SUCCESS';
export const LOAD_AUTHORIZATION_RESOURCE_FAILURE =
  'LOAD_AUTHORIZATION_RESOURCE_FAILURE';
export const LOAD_CURRENT_USER_AUTHORIZATIONS_FAILURE =
  'LOAD_CURRENT_USER_AUTHORIZATIONS_FAILURE';
export const LOAD_CURRENT_USER_AUTHORIZATIONS_SUCCESS =
  'LOAD_CURRENT_USER_AUTHORIZATIONS_SUCCESS';
export const LOAD_MANAGED_RESOURCES_SUCCESS = 'LOAD_MANAGED_RESOURCES_SUCCESS';
export const LOAD_MANAGED_RESOURCES_FAILURE = 'LOAD_MANAGED_RESOURCES_FAILURE';
export const LOAD_UNMANAGED_RESOURCES_SUCCESS =
  'LOAD_UNMANAGED_RESOURCES_SUCCESS';
export const LOAD_UNMANAGED_RESOURCES_FAILURE =
  'LOAD_UNMANAGED_RESOURCES_FAILURE';
export const LOAD_MANAGED_ROLES_SUCCESS = 'LOAD_MANAGED_ROLES_SUCCESS';
export const LOAD_MANAGED_ROLES_FAILURE = 'LOAD_MANAGED_ROLES_FAILURE';
export const LOAD_UNMANAGED_ROLES_SUCCESS = 'LOAD_UNMANAGED_ROLES_SUCCESS';
export const LOAD_UNMANAGED_ROLES_FAILURE = 'LOAD_UNMANAGED_ROLES_FAILURE';
export const LOAD_AUTHORIZATIONS_SUCCESS = 'LOAD_AUTHORIZATIONS_SUCCESS';
export const LOAD_AUTHORIZATIONS_FAILURE = 'LOAD_AUTHORIZATIONS_FAILURE';
export const LOAD_AUTHORIZATION_SUCCESS = 'LOAD_AUTHORIZATION_SUCCESS';
export const LOAD_AUTHORIZATION_FAILURE = 'LOAD_AUTHORIZATION_FAILURE';
export const CREATE_AUTHORIZATION_SUCCESS = 'CREATE_AUTHORIZATION_SUCCESS';
export const CREATE_AUTHORIZATION_FAILURE = 'CREATE_AUTHORIZATION_FAILURE';
export const UPDATE_AUTHORIZATION_SUCCESS = 'UPDATE_AUTHORIZATION_SUCCESS';
export const UPDATE_AUTHORIZATION_FAILURE = 'UPDATE_AUTHORIZATION_FAILURE';
export const REMOVE_AUTHORIZATION_SUCCESS = 'REMOVE_AUTHORIZATION_SUCCESS';
export const REMOVE_AUTHORIZATION_FAILURE = 'REMOVE_AUTHORIZATION_FAILURE';

export const TB_PROCESSING_COMPLETE = 'TB_PROCESSING_COMPLETE';
export const TB_PROCESSING_FAILED = 'TB_PROCESSING_FAILED';
export const TB_PROCESSING_COMPLETE_NOTIFIED =
  'TB_PROCESSING_COMPLETE_NOTIFIED';

export const PERCENTAGE_OF_OWNERSHIP_UPDATE_SUCCESS =
  'PERCENTAGE_OF_OWNERSHIP_UPDATE_SUCCESS';
export const PERCENTAGE_OF_OWNERSHIP_UPDATE_FAILURE =
  'PERCENTAGE_OF_OWNERSHIP_UPDATE_FAILURE';

export const CREATE_MAPPING_ALL_REITS_SUCCESS =
  'CREATE_MAPPING_ALL_REITS_SUCCESS';
export const CREATE_MAPPING_ALL_REITS_FAILURE =
  'CREATE_MAPPING_ALL_REITS_FAILURE';

export const ADD_NOTIFICATIONS_FAILURE = 'ADD_NOTIFICATIONS_FAILURE';
export const ADD_NOTIFICATIONS_SUCCESS = 'ADD_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const TOGGLE_NOTIFICATIONS_DISPLAY = 'TOGGLE_NOTIFICATIONS_DISPLAY';

export const SHOW_BULK_UPLOAD_MODAL = 'SHOW_BULK_UPLOAD_MODAL';
export const HIDE_BULK_UPLOAD_MODAL = 'HIDE_BULK_UPLOAD_MODAL';

export const TB_UPLOAD_CLEAR = 'TB_UPLOAD_CLEAR';
export const TB_UPLOAD_COMPLETE = 'TB_UPLOAD_COMPLETE';
export const TB_UPLOAD_FAILED = 'TB_UPLOAD_FAILED';
export const TB_UPLOAD_PROGRESS = 'TB_UPLOAD_PROGRESS';
export const TB_UPLOAD_START = 'TB_UPLOAD_START';
export const TB_ADD_FILES_TO_QUEUE = 'TB_ADD_FILES_TO_QUEUE';

export const LOAD_QUEUE_COUNT_SUCCESS = 'LOAD_QUEUE_COUNT_SUCCESS';
export const LOAD_QUEUE_COUNT_FAILURE = 'LOAD_QUEUE_COUNT_FAILURE';

export const AUTHENTICATION_TOKEN_EXPIRED = 'AUTHENTICATION_TOKEN_EXPIRED';
export const AUTHENTICATION_TIMER_UPDATED = 'AUTHENTICATION_TIMER_UPDATED';

export const EDIT_SAVED_CHECKLIST_COMMENT_SUCCESS = 'EDIT_SAVED_CHECKLIST_COMMENT_SUCCESS';
export const EDIT_SAVED_CHECKLIST_COMMENT_FAILURE = 'EDIT_SAVED_CHECKLIST_COMMENT_FAILURE';

export const DELETE_SAVED_CHECKLIST_COMMENT_SUCCESS = 'DELETE_SAVED_CHECKLIST_COMMENT_SUCCESS';
export const DELETE_SAVED_CHECKLIST_COMMENT_FAILURE = 'DELETE_SAVED_CHECKLIST_COMMENT_FAILURE';

export const CREATE_CUSTOM_QUESTION_SUCCESS = 'CREATE_CUSTOM_QUESTION_SUCCESS';
export const CREATE_CUSTOM_QUESTION_FAILURE = 'CREATE_CUSTOM_QUESTION_FAILURE';
export const LOAD_CUSTOM_QUESTIONS_SUCCESS = 'LOAD_CUSTOM_QUESTIONS_SUCCESS';
export const LOAD_CUSTOM_QUESTIONS_FAILURE = 'LOAD_CUSTOM_QUESTIONS_FAILURE';
export const UPDATE_CUSTOM_QUESTION_SUCCESS = 'UPDATE_CUSTOM_QUESTION_SUCCESS';
export const UPDATE_CUSTOM_QUESTION_FAILURE = 'UPDATE_CUSTOM_QUESTION_FAILURE';
export const DELETE_CUSTOM_QUESTION_SUCCESS = 'DELETE_CUSTOM_QUESTION_SUCCESS';
export const DELETE_CUSTOM_QUESTION_FAILURE = 'DELETE_CUSTOM_QUESTION_FAILURE';

export const LOAD_ALL_REIT_LOOKUPTYPES_SUCCESS = 'LOAD_ALL_REIT_LOOKUPTYPES_SUCCESS';
export const LOAD_PROPERTY_TYPES_SUCCESS = 'LOAD_PROPERTY_TYPES_SUCCES';
export const LOAD_PSQ_PROPERTY_TYPES_SUCCESS = 'LOAD_PSQ_PROPERTY_TYPES_SUCCESS';
export const LOAD_ALL_PROPERTY_TYPES_SUCCESS = 'LOAD_ALL_PROPERTY_TYPES_SUCCESS';

export const UPDATE_CHART_AS_DEFAULT_FAILURE = 'UPDATE_CHART_AS_DEFAULT_FAILURE';
export const UPDATE_CHART_NAME_FAILURE = 'UPDATE_CHART_NAME_FAILURE';
export const UPLOAD_CHART_FAILURE = 'UPLOAD_CHART_FAILURE';
export const UPLOAD_CHART_SUCCESS = 'UPLOAD_CHART_SUCCESS';
export const LOAD_ALL_CHARTOFACCOUNT_TYPES_SUCCESS = 'LOAD_ALL_CHARTOFACCOUNT_TYPES_SUCCESS';
export const SYNC_REIT_TESTSING_ATTRIBUTES_REIT_PROPERTY_FAILURE = 'SYNC_REIT_TESTSING_ATTRIBUTES_REIT_PROPERTY_FAILURE';
export const SYNC_REIT_TESTSING_ATTRIBUTES_REPORT_PERIOD = 'SYNC_REIT_TESTSING_ATTRIBUTES_REPORT_PERIOD';
export const DOWNLOAD_TB_TEMPLATE_FAILURE = 'DOWNLOAD_TB_TEMPLATE_FAILURE';

export const LOAD_REIT_FILES_SUCCESS = 'LOAD_REIT_FILES_SUCCESS';
export const LOAD_REIT_FILES_FAILURE = 'LOAD_REIT_FILES_FAILURE';
export const UPLOAD_REIT_FILE_SUCCESS = 'UPLOAD_REIT_FILE_SUCCESS';
export const UNLOAD_REIT_FILE_MANAGEMENT = 'UNLOAD_REIT_FILE_MANAGEMENT';

export const LOAD_MANAGE_CHECKLIST_SUCCESS = 'LOAD_MANAGE_CHECKLIST_SUCCESS';
export const LOAD_MANAGE_CHECKLIST_FAILURE = 'LOAD_MANAGE_CHECKLIST_FAILURE';
export const LOAD_EXTERNAL_CHECKLIST_SUCCESS = 'LOAD_EXTERNAL_CHECKLIST_SUCCESS';
export const LOAD_EXTERNAL_CHECKLIST_FAILURE = 'LOAD_EXTERNAL_CHECKLIST_FAILURE';
export const ADD_CHECKLIST_USERS_FAILURE = 'ADD_CHECKLIST_USERS_FAILURE';
export const ADD_CHECKLIST_USERS_SUCCESS = 'ADD_CHECKLIST_USERS_SUCCESS';
export const DELETE_CHECKLIST_USERS_FAILURE = 'DELETE_CHECKLIST_USERS_FAILURE';
export const DELETE_CHECKLIST_USERS_SUCCESS = 'DELETE_CHECKLIST_USERS_SUCCESS';
export const DELETE_CHECKLISTS_FAILURE = 'DELETE_CHECKLISTS_FAILURE';
export const DELETE_CHECKLISTS_SUCCESS = 'DELETE_CHECKLISTS_SUCCESS';
export const RESEND_CHECKLISTS_FAILURE = 'RESEND_CHECKLISTS_FAILURE';
export const EXPORT_CHECKLIST_SUMMARY_FAILURE = 'EXPORT_CHECKLIST_SUMMARY_FAILURE';
export const EXPORT_CHECKLIST_SUMMARY_SUCCESS = 'EXPORT_CHECKLIST_SUMMARY_SUCCESS';
export const UPDATE_CHECKLISTS_STATE_FAILURE = 'UPDATE_CHECKLISTS_STATE_FAILURE';
export const UPDATE_CHECKLISTS_STATE_SUCCESS = 'UPDATE_CHECKLISTS_STATE_SUCCESS';
export const APPROVE_DENY_CHECKLISTS_REQUEST_FAILURE = 'APPROVE_DENY_CHECKLISTS_REQUEST_FAILURE';
export const APPROVE_DENY_CHECKLISTS_REQUEST_SUCCESS = 'APPROVE_DENY_CHECKLISTS_REQUEST_SUCCESS';
export const REFRESH_MANAGE_CHECKLIST_CLIENT = 'REFRESH_MANAGE_CHECKLIST_CLIENT';
export const REOPEN_CHECKLIST_REQUESTS_FAILURE = 'REOPEN_CHECKLIST_REQUESTS_FAILURE';
export const SUBMITFOREYREVIEW_REQUESTS_FAILURE = 'SUBMITFOREYREVIEW_REQUESTS_FAILURE';
export const SIGNOFF_CHECKLIST_REQUESTS_FAILURE = 'SIGNOFF_CHECKLIST_REQUESTS_FAILURE';

// Custom PSQ Template Actions
export const LOAD_CUSTOM_PSQ_TEMPLATE_SUCCESS = 'LOAD_CUSTOM_PSQ_TEMPLATE_SUCCESS';
export const LOAD_CUSTOM_PSQ_TEMPLATE_FAILURE = 'LOAD_CUSTOM_PSQ_TEMPLATE_FAILURE';
export const LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLE_SUCCESS = 'LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLE_SUCCESS';
export const LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLE_FAILURE = 'LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLE_FAILURE';
export const DELETE_CUSTOM_PSQ_TEMPLATE_SUCCESS = 'DELETE_CUSTOM_PSQ_TEMPLATE_SUCCESS';
export const DELETE_CUSTOM_PSQ_TEMPLATE_FAILURE = 'DELETE_CUSTOM_PSQ_TEMPLATE_FAILURE';
export const CREATE_CUSTOM_PSQ_TEMPLATE_SUCCESS = 'CREATE_CUSTOM_PSQ_TEMPLATE_SUCCESS';
export const CREATE_CUSTOM_PSQ_TEMPLATE_FAILURE = 'CREATE_CUSTOM_PSQ_TEMPLATE_FAILURE';
export const DELETE_TEMPLATE_QUESTION_FAILURE = 'DELETE_TEMPLATE_QUESTION_FAILURE';
export const LOAD_ALL_CUSTOM_CHECKLIST_TEMPLATE_LOOKUPTYPES_SUCCESS = 'LOAD_ALL_CUSTOM_CHECKLIST_TEMPLATE_LOOKUPTYPES_SUCCESS';
export const LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLEBYID_SUCCESS = 'LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLEBYID_SUCCESS';
export const LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLEBYID_FAILURE = 'LOAD_CUSTOM_QUESTIONNAIRE_AVAILABLEBYID_FAILURE';
export const UPSERT_CUSTOM_PSQ_TEMPLATE_SUCCESS = 'UPSERT_CUSTOM_PSQ_TEMPLATE_SUCCESS';
export const UPSERT_CUSTOM_PSQ_TEMPLATE_FAILURE = 'UPSERT_CUSTOM_PSQ_TEMPLATE_FAILURE';
export const LOAD_CUSTOM_PSQ_TEMPLATE_CONTENT_SUCCESS = 'LOAD_CUSTOM_PSQ_TEMPLATE_CONTENT_SUCCESS';
export const LOAD_CUSTOM_PSQ_TEMPLATE_CONTENT_FAILURE = 'LOAD_CUSTOM_PSQ_TEMPLATE_CONTENT_FAILURE';
export const UPDATE_CHECKLIST_TEMPLATE_TO_LOCK_UNLOCK_SUCCESS = 'UPDATE_CHECKLIST_TEMPLATE_TO_LOCK_UNLOCK_SUCCESS';
export const UPDATE_CHECKLIST_TEMPLATE_TO_LOCK_UNLOCK_FAILURE = 'UPDATE_CHECKLIST_TEMPLATE_TO_LOCK_UNLOCK_FAILURE';
export const UPDATE_CHECKLIST_TEMPLATE_TO_ENABLE_EDIT_MODE_SUCCESS = 'UPDATE_CHECKLIST_TEMPLATE_TO_ENABLE_EDIT_MODE_SUCCESS';
export const UPDATE_CHECKLIST_TEMPLATE_TO_ENABLE_EDIT_MODE_FAILURE = 'UPDATE_CHECKLIST_TEMPLATE_TO_ENABLE_EDIT_MODE_FAILURE';
export const PERSIST_UNCOMMITTED_TEMPLATE_SECTION_CHANGES_FAILURE = 'PERSIST_UNCOMMITTED_TEMPLATE_SECTION_CHANGES_FAILURE';
export const COMMIT_TEMPLATE_SECTION_CHANGES_FAILURE = 'COMMIT_TEMPLATE_SECTION_CHANGES_FAILURE';
export const ABANDON_TEMPLATE_SECTION_CHANGES_FAILURE = 'ABANDON_TEMPLATE_SECTION_CHANGES_FAILURE';
export const LOAD_CHECKLIST_TEMPLATE_SUCCESS = 'LOAD_CHECKLIST_TEMPLATE_SUCCESS';
export const LOAD_CHECKLIST_TEMPLATE_FAILURE = 'LOAD_CHECKLIST_TEMPLATE_FAILURE';

// Checklist Delivery Schedule
export const CHECKLIST_SCHEDULED_DELIVERY_SUCCESS = 'CHECKLIST_SCHEDULED_DELIVERY_SUCCESS';
export const CHECKLIST_SCHEDULED_DELIVERY_FAILURE = 'CHECKLIST_SCHEDULED_DELIVERY_FAILURE';

export const UPLOAD_CHECKLIST_DELIVERY_SCHEDULE_FAILURE = 'UPLOAD_CHECKLIST_DELIVERY_SCHEDULE_FAILURE';
export const UPLOAD_CHECKLIST_DELIVERY_SCHEDULE_SUCCESS = 'UPLOAD_CHECKLIST_DELIVERY_SCHEDULE_SUCCESS';
export const LOAD_ATTACHMENTDETAIL_SUCCESS = 'LOAD_ATTACHMENTDETAIL_SUCCESS';
export const LOAD_ATTACHMENTDETAIL_FAILURE = 'LOAD_ATTACHMENTDETAIL_FAILURE';

export const LOAD_QUESTION_COMMENTS_SUCCESS = 'LOAD_QUESTION_COMMENTS_SUCCESS';
export const LOAD_QUESTION_COMMENTS_FAILURE = 'LOAD_QUESTION_COMMENTS_FAILURE';

export const CHECKLIST_NAVIGATION_COUNTER_SUCCESS = 'CHECKLIST_NAVIGATION_COUNTER_SUCCESS';
export const CHECKLIST_NAVIGATION_COUNTER_FAILURE = 'CHECKLIST_NAVIGATION_COUNTER_FAILURE';
export const DELETE_ATTACHMENT_FAILURE = 'DELETE_ATTACHMENT_FAILURE';
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE';

export const LOAD_QUESTION_DISCREPANCIES_SUCCESS = 'LOAD_QUESTION_DISCREPANCIES_SUCCESS';
export const LOAD_QUESTION_DISCREPANCIES_FAILURE = 'LOAD_QUESTION_DISCREPANCIES_FAILURE';

export const SAVE_COMMENT_SUCCESS = 'SAVE_COMMENT_SUCCESS';
export const SAVE_COMMENT_FAILURE = 'SAVE_COMMENT_FAILURE';

export const INSERT_SERVICE_PROVIDER_QUESTION_FAILURE = 'INSERT_SERVICE_PROVIDER_QUESTION_FAILURE';
export const REMOVE_SERVICE_PROVIDER_QUESTION_FAILURE = 'REMOVE_SERVICE_PROVIDER_QUESTION_FAILURE';
export const LOAD_LAST_SERVICE_PROVIDER_QUESTION_ID_SUCCESS = 'LOAD_LAST_SERVICE_PROVIDER_QUESTION_ID_SUCCESS';
export const LOAD_LAST_SERVICE_PROVIDER_QUESTION_ID_FAILURE = 'LOAD_LAST_SERVICE_PROVIDER_QUESTION_ID_FAILURE';

export const DELETE_AUTH_FAILURE = 'DELETE_AUTH_FAILURE';

// Microsoft Graph API
export const LOAD_USER_PROFILE_PICTURE_SUCCESS = 'LOAD_USER_PROFILE_PICTURE_SUCCESS';
export const LOAD_USER_PROFILE_PICTURE_FAILURE = 'LOAD_USER_PROFILE_PICTURE_FAILURE';


export const UPDATE_PSQ_COMPLETION_REQ_STATUS_SUCCESS = 'UPDATE_PSQ_COMPLETION_REQ_STATUS_SUCCESS';
export const UPDATE_PSQ_COMPLETION_REQ_STATUS_FAILURE = 'UPDATE_PSQ_COMPLETION_REQ_STATUS_FAILURE';

export const UPDATE_IS_LOCKED_FOR_MISSING_DELETION_APPROVAL_OR_EXCEPTION_STATUS_SUCCESS = 'UPDATE_IS_LOCKED_FOR_MISSING_DELETION_APPROVAL_OR_EXCEPTION_STATUS_SUCCESS';
export const UPDATE_IS_LOCKED_FOR_MISSING_DELETION_APPROVAL_OR_EXCEPTION_STATUS_FAILURE = 'UPDATE_IS_LOCKED_FOR_MISSING_DELETION_APPROVAL_OR_EXCEPTION_STATUS_FAILURE';

// Custom Question Lock
export const CUSTOM_QUESTION_FETCH_LOCK_STATUS_SUCCESS = 'CUSTOM_QUESTION_FETCH_LOCK_STATUS_SUCCESS';
export const CUSTOM_QUESTION_FETCH_LOCK_STATUS_FAILURE = 'CUSTOM_QUESTION_FETCH_LOCK_STATUS_FAILURE';
export const CUSTOM_QUESTION_INTENT_LOCK_UPDATE_SUCCESS = 'CUSTOM_QUESTION_INTENT_LOCK_UPDATE_SUCCESS';
export const CUSTOM_QUESTION_INTENT_LOCK_UPDATE_FAILURE = 'CUSTOM_QUESTION_INTENT_LOCK_UPDATE_FAILURE';

// Bulk Process Management
export const LOAD_BULK_PROCESS_MANAGEMENT_DATA_SUCCESS = 'LOAD_BULK_PROCESS_MANAGEMENT_DATA_SUCCESS';
export const LOAD_BULK_PROCESS_MANAGEMENT_DATA_FAILURE = 'LOAD_BULK_PROCESS_MANAGEMENT_DATA_FAILURE';
export const LOAD_BULK_PROCESS_MANAGEMENT_DATA_REFRESH = 'LOAD_BULK_PROCESS_MANAGEMENT_DATA_REFRESH';
export const RECEIVE_BULK_PROCESS_STATUS_UPDATE_MESSAGE ='RECEIVE_BULK_PROCESS_STATUS_UPDATE_MESSAGE';
export const RECEIVE_BULK_PROCESS_STATUS_CONCURRENT_UPDATE_MESSAGE ='RECEIVE_BULK_PROCESS_STATUS_CONCURRENT_UPDATE_MESSAGE';
export const INITIATE_BULK_PROCESS_SUCCESS = 'INITIATE_BULK_PROCESS_SUCCESS';
export const INITIATE_BULK_PROCESS_FAILURE = 'INITIATE_BULK_PROCESS_FAILURE';

// Bulk Roll Forward
export const LOAD_BULK_ROLL_FORWARD_DATA_SUCCESS = 'LOAD_BULK_ROLL_FORWARD_DATA_SUCCESS';
export const LOAD_BULK_ROLL_FORWARD_DATA_FAILURE = 'LOAD_BULK_ROLL_FORWARD_DATA_FAILURE';
export const INITIATE_BULK_ROLL_FORWARD_PROCESS_SUCCESS = 'INITIATE_BULK_ROLL_FORWARD_PROCESS_SUCCESS';
export const INITIATE_BULK_ROLL_FORWARD_PROCESS_FAILURE = 'INITIATE_BULK_ROLL_FORWARD_PROCESS_FAILURE';
export const RECEIVE_BULK_ROLL_FORWARD_STATUS_UPDATE_MESSAGE ='RECEIVE_BULK_ROLL_FORWARD_STATUS_UPDATE_MESSAGE';
export const LOAD_BULK_ROLL_FORWARD_DATA_REFRESH ='LOAD_BULK_ROLL_FORWARD_DATA_REFRESH';
export const RECEIVE_BULK_ROLL_FORWARD_STATUS_CONCURRENT_UPDATE_MESSAGE ='RECEIVE_BULK_ROLL_FORWARD_STATUS_CONCURRENT_UPDATE_MESSAGE';

// Motif themes
export const MOTIF_THEME = 'MOTIF_THEME';
// export const MOTIF_THEME_DARK = 'MOTIF_THEME_DARK'
// export const MOTIF_THEME_LIGHT = 'MOTIF_THEME_LIGHT'

//Data Retention Management

export const LOAD_DATA_RETENTION_MANAGEMENT_DATA_SUCCESS ='LOAD_DATA_RETENTION_MANAGEMENT_DATA_SUCCESS';
export const LOAD_DATA_RETENTION_MANAGEMENT_DATA_FAILURE='LOAD_DATA_RETENTION_MANAGEMENT_DATA_FAILURE';
export const ACTION_DATA_RETENTION_MANAGEMENT_FAILURE='ACTION_DATA_RETENTION_MANAGEMENT_FAILURE';

//Data Purge

export const LOAD_DATA_PURGE_DATA_SUCCESS ='LOAD_DATA_PURGE_DATA_SUCCESS';
export const LOAD_DATA_PURGE_DATA_FAILURE='LOAD_DATA_PURGE_DATA_FAILURE';
export const ACTION_DATA_PURGE_FAILURE='ACTION_DATA_PURGE_FAILURE';
export const DATA_DELETION_LOAD_CALENDAR_YEARS_FAILURE = 'DATA_DELETION_LOAD_CALENDAR_YEARS_FAILURE';
export const LOAD_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_SUCCESS ='LOAD_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_SUCCESS';
export const LOAD_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_FAILURE='LOAD_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_FAILURE';
export const CREATE_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_SUCCESS ='CREATE_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_SUCCESS';
export const CREATE_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_FAILURE='CREATE_DATA_PURGE_USER_ACKNOWLEDGEMENT_HISTORY_FAILURE';
export const LOAD_UPCOMING_DATA_DELETION_NOTICE_DETAILS_SUCCESS ='LOAD_UPCOMING_DATA_DELETION_NOTICE_DETAILS_SUCCESS';
export const LOAD_UPCOMING_DATA_DELETION_NOTICE_DETAILS_FAILURE='LOAD_UPCOMING_DATA_DELETION_NOTICE_DETAILS_FAILURE';
export const LOAD_DATA_PURGE_APPROVED_STATUS_FAILURE = 'LOAD_DATA_PURGE_APPROVED_STATUS_FAILURE';
export const LOAD_FEATURE_FLAGS_SUCCESS ='LOAD_FEATURE_FLAGS_SUCCESS';
export const LOAD_FEATURE_FLAGS_FAILURE='LOAD_FEATURE_FLAGS_FAILURE';


export const LOAD_DATA_PURGE_DIAGDATA_SUCCESS ='LOAD_DATA_PURGE_DATA_SUCCESS';
export const LOAD_DATA_PURGE_DIAGDATA_FAILURE='LOAD_DATA_PURGE_DATA_FAILURE';

export const ACTION_RUN_DATA_DELETION_FAILURE='ACTION_RUN_DATA_DELETION_FAILURE';


export const LOAD_CAN_SWITCH_TYPE_FAILURE = 'LOAD_CAN_SWITCH_TYPE_FAILURE';
export const LOAD_CAN_SWITCH_TYPE_SUCCESS = 'LOAD_CAN_SWITCH_TYPE_SUCCESS';

//External User Data Visibility
export const LOAD_External_User_Data_Visibility_SUCCESS = 'LOAD_External_User_Data_Visibility_SUCCESS';
export const LOAD_External_User_Data_Visibility_FAILURE = 'LOAD_External_User_Data_Visibility_FAILURE';

export const ACTION_External_User_Data__Hide_Visibility_SUCCESS = 'ACTION_External_User_Data__Hide_Visibility_SUCCESS';
export const ACTION_External_User_Data__Hide_Visibility_FAILURE = 'ACTION_External_User_Data__Hide_Visibility_FAILURE';

export const ACTION_External_User_Data__UnHide_Visibility_SUCCESS = 'ACTION_External_User_Data__UnHide_Visibility_SUCCESS';
export const ACTION_External_User_Data__UnHide_Visibility_FAILURE = 'ACTION_External_User_Data__UnHide_Visibility_FAILURE';
