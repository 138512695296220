import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import * as dateHelpers from "../../scripts/dateHelpers";
import * as states from "../../constants/states";
import { DateField, MoneyField, NumberField, EinField } from "../shared/formFields";
import PropertyAmenitiesTableContainer from "../../containers/shared/PropertyAmenitiesTableContainer";
import $ from 'jquery';
import { MotifButton, MotifModalBody, MotifModalFooter, MotifToast } from "@ey-xd/motif-react";
import { EditPropertyFields } from './EditPropertyForm';
import { addAttribute } from "../../common/Attribute";
import { useSelector } from 'react-redux';
import * as formParsers from "../../scripts/formParsers";
const EditReitForm = ({ handleSubmit,
    handleCloseForm,
    initialValues,
    reitFormPurpose,
    showReitSuccess,
    error,
    submitting,
    handleDismissErrorMessage,
    isTestingEnabled,
    onSubmit,
    fields,
    lookupTypes,
    isAudit }) => {

    const [hideInfoMessage, setHideInfoMessage] = useState(false);
    const featureFlags = useSelector(
        state => state.featureManagementData
    );
    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    const options = lookupTypes.psqPropertyTypes
        .filter(ppt => ppt.psqPropertyTypeID !== 0)
        .map(option => {
            const psqOption = {};
            psqOption.key = option.psqPropertyTypeID;
            psqOption.value = option.psqPropertyTypeDescription;
            psqOption.text = option.psqPropertyTypeDescription;
            return psqOption;
        });

    let submitButtonText = "Submit";
    if (reitFormPurpose === "EDIT") {
        submitButtonText = "Save";
    }

    let scrollTop = () => {
        $('.ReactModal__Content').scrollTop(0);
    };

    let save = (e) => {
        e.preventDefault();
        scrollTop();

        handleSubmit(values => onSubmit({ ...values, anotherProperty: 'NO' }))();
    };

    let saveAndCreateAnotherProperty = (e) => {
        e.preventDefault();
        scrollTop();

        handleSubmit(values => onSubmit({ ...values, anotherProperty: 'YES' }))();
    };

    const currentYear = new Date().getFullYear();
    let yearOptions = [];
    const dropdownStartYear = isAudit == true ? currentYear - 1 : currentYear - 5;
    let initialYear = featureFlags && featureFlags['RestrictedReportPeriodLimit'] ? dropdownStartYear : currentYear - 5;

    for (let i = initialYear; i <= currentYear + 10; i++) {
        yearOptions.push(i.toString());
    }

  
    const [showPsqTypes, setShowPsqTypes] = useState(true);

    const rerenderPsqTypes = () => {
        setShowPsqTypes(false);
        setTimeout(() => setShowPsqTypes(true));
    }

    return (
        <>
            <MotifModalBody>
                <form onSubmit={handleSubmit} className="reit" id="reitForm">
                    <Field
                        name="reitid"
                        component={formHelpers.renderField}
                        type="hidden"
                    />
                    <section className="reit">
                        {formHelpers.showSubmissionError(
                            error,
                            handleDismissErrorMessage
                        )}
                        <div className="row">
                            <div className="field col-md-6">
                                <Field
                                    name="reitName"
                                    component={formHelpers.renderField}
                                    type="text"
                                    label="Full Name"
                                    isRequiredField={true}
                                    maxLength={50}
                                    validate={[
                                        formValidators.required,
                                        formValidators.minLength2,
                                        formValidators.maxLength50,
                                        formValidators.noWindowSpecialChars,
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="field col-md-6">
                                <Field
                                    name="reitShortName"
                                    component={formHelpers.renderField}
                                    type="text"
                                    label="Short Name"
                                    maxLength={50}
                                    isRequiredField={true}
                                    validate={[
                                        formValidators.required,
                                        formValidators.minLength2,
                                        formValidators.maxLength50,
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="field col-md-2 marginTop">
                                <EinField
                                    name="ein"
                                    label="EIN"
                                    placeholder="XX-XXXXXXX"
                                    isRequiredField={true}
                                    validate={[
                                        formValidators.required,
                                        formValidators.maxLength50,
                                        formValidators.ein,
                                    ]}
                                />
                            </div>
                            <div className="field col-md-2 marginTop">
                                <Field
                                    name="symbol"
                                    component={formHelpers.renderField}
                                    type="text"
                                    label="REIT Symbol"
                                    maxLength={50}
                                    validate={[formValidators.maxLength50]}
                                /></div>
                            <div className="field col-md-3">
                                <Field
                                    name="reitTypeID"
                                    label="REIT Type (Required)"
                                    placeholder="Select a Type"
                                    component={formHelpers.SingleSelectComponent}
                                    type="select"
                                    options={lookupTypes.reitTypes}
                                    optionValue={(o) => o.reitTypeID}
                                    optionText={(o) => o.reitTypeDescription}
                                    validate={[formValidators.requiredSelect]}
                                ></Field>
                            </div>
                            <div className="field col-md-5">
                                {!hideInfoMessage && showPsqTypes && reitFormPurpose === "EDIT" &&
                                    <MotifToast className="mb-3" variant="error" onClose={setHideInfoMessage} data-testid="reitTypeToastMsg">
                                        Warning: Changing the REIT Type will change the default ARQC assigned to this REIT on the checklist auto delivery calendar.
                                    </MotifToast>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="field col-md-4">
                                <div>
                                    <Field
                                        name="organizationTypeID"
                                        label="Organization Type (Required)"
                                        placeholder="Select an Organization Type"
                                        component={formHelpers.SingleSelectComponent}
                                        type="select"
                                        options={lookupTypes.organizationTypes.filter(
                                            (o) => o.organizationTypeID !== 0
                                        )}
                                        optionValue={(o) => o.organizationTypeID}
                                        optionText={(o) =>
                                            o.organizationTypeDescription
                                        }
                                        validate={[formValidators.requiredSelect]}
                                    />
                                </div>
                            </div>
                            <div className="field col-md-4 marginTop">
                                <DateField
                                    name="formationDate"
                                    label="Formation Date"
                                    placeholder="MM/DD/YYYY"
                                    isRequiredField={true}
                                    validate={[
                                        formValidators.required,
                                        formValidators.isValidDate,
                                    ]}
                                />
                            </div>
                            {(reitFormPurpose === "" && (
                                <div className="field col-md-4">
                                    <div className="row">
                                        <div
                                            className="col-sm-6"
                                            style={{ paddingRight: 0 }}
                                        >
                                            <Field
                                                name="periodQuarter"
                                                label="Create for Period"
                                                placeholder="Quarter"
                                                component={
                                                    formHelpers.SingleSelectComponent
                                                }
                                                type="select"
                                                options={[1, 2, 3, 4]}
                                                optionValue={(q) => q}
                                                optionText={(q) => `Q${q}`}
                                                validate={[
                                                    formValidators.requiredSelect,
                                                ]}
                                            />
                                        </div>
                                        <div
                                            className="col-sm-6"
                                            style={{ paddingLeft: 0 }}
                                        >
                                            <Field
                                                name="periodYear"
                                                label=""
                                                placeholder="Year"
                                                component={
                                                    formHelpers.SingleSelectComponent
                                                }
                                                type="select"
                                                options={yearOptions}
                                                optionValue={(y) => y}
                                                optionText={(y) => y}
                                                validate={[
                                                    formValidators.requiredSelect,
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )) ||
                                null}
                        </div>
                        <div className="row">
                            <div className="field col-md-4 marginTop">
                                <NumberField
                                    name="percentageThreshold"
                                    label="Percentage Threshold"
                                    placeholder="0.00"
                                    allowDecimal={true}
                                    isRequiredField={true}
                                    validate={[
                                        formValidators.required,
                                        formValidators.number
                                    ]}
                                    parse={formParsers.positiveNumberParser} // add this parse to prevent accepting negative (-) value
                                />
                            </div>
                            <div className="field col-xl-4 col-lg-5 col-md-7 marginTop">
                                <MoneyField
                                    name="balanceThreshold"
                                    label="Balance Threshold"
                                    isRequiredField={true}
                                    allowDecimal={true}
                                    validate={[
                                        formValidators.required,
                                        formValidators.maxValueDecimal,
                                        formValidators.number
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="field col-md-4">
                                <NumberField
                                    name="numberOfSharesClasses"
                                    label="# Shares Classes"
                                    placeholder="0"
                                    allowDecimal={true}
                                    parse={formParsers.positiveWholeNumberParser} // add this parse to prevent accepting negative (-) value and decimal
                                    validate={[ 
                                        formValidators.number,  
                                        formValidators.maxValueInt,                                    
                                        formValidators.isWholeNumber
                                    ]}
                                />
                            </div>
                            <div className="field col-xl-4 col-lg-5 col-md-7">
                                <NumberField
                                    name="preferredNumberOfShareHolders"
                                    allowDecimal={true}
                                    label="# Preferred ShareHolders"
                                    placeholder="0"
                                    validate={[ 
                                        formValidators.number,  
                                        formValidators.maxValueInt,                                    
                                        formValidators.isWholeNumber
                                    ]}
                                    parse={formParsers.positiveWholeNumberParser} // add this parse to prevent accepting negative (-) value
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="field col-md-4">
                                <Field
                                    name="stateOrganized"
                                    label="State Organized In (Required)"
                                    placeholder="Select a State"
                                    component={formHelpers.SingleSelectComponent}
                                    type="select"
                                    options={states.usStates}
                                    optionValue={(s) => s.value}
                                    optionText={(s) => s.label}
                                    validate={[formValidators.requiredSelect]}
                                />
                            </div>
                            <div className="field col-xl-4 col-lg-5 col-md-7 marginTop">
                                <DateField
                                    name="dateOfElection"
                                    label="Date of REIT Election"
                                    isRequiredField={true}
                                    validate={[
                                        formValidators.required,
                                        formValidators.isValidDate,
                                    ]}
                                />
                            </div>
                        </div>
                    </section>

                    {reitFormPurpose === "" && (
                        <section className="property">
                            <div className="row mt-5">
                                <div className="row ml-3">
                                    <h4>Create New Property</h4>
                                </div>
                            </div>
                            <hr/>

                            <EditPropertyFields formPurpose={reitFormPurpose} lookupTypes={lookupTypes} />

                            <div className="row">
                                <div className="field col">
                                    <label>Tenant Services / Amenities</label>
                                    <div>
                                        <PropertyAmenitiesTableContainer formName="reit" />
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </form>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton
                    variant="secondary"
                    type="button"
                    onClick={handleCloseForm}
                    form="reitForm"
                >
                    Cancel
                </MotifButton>
                {(reitFormPurpose !== "EDIT" && (
                    <MotifButton
                        variant="primary-alt"
                        type="submit"
                        onClick={saveAndCreateAnotherProperty}
                        disabled={submitting}
                        name="saveAndNewProperty"
                        id="save-and-new-property"
                        form="reitForm"
                    >
                        Submit and Create Another Property
                    </MotifButton>
                )) ||
                    null}
                <MotifButton
                    variant="primary"
                    type="submit"
                    onClick={save}
                    disabled={submitting}
                    form="reitForm"
                >
                    {submitButtonText}
                </MotifButton>
            </MotifModalFooter>
        </>
    );
};

EditReitForm.propTypes = {
    lookupTypes: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    handleCloseForm: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    error: PropTypes.object,
    submitting: PropTypes.bool,
    reitFormPurpose: PropTypes.string,
    showReitSuccess: PropTypes.bool,
    handleDismissErrorMessage: PropTypes.func,
    isTestingEnabled: PropTypes.bool,
    fields: PropTypes.array,
    onSubmit: PropTypes.func.isRequired,
    clientIsAudit: PropTypes.bool,
};

export default reduxForm({ form: "reit" })(EditReitForm);