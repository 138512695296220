import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getFormValues, SubmissionError, Field, reduxForm, reset, change } from "redux-form";
import * as actions from "../../actions/customFdrTemplateActions";
import CustomFdrTemplateHeader from "../../components/customFdrTemplate/CustomFdrTemplateHeader";
import CustomFdrTemplateEditor from "../../components/customFdrTemplate/CustomFdrTemplateEditor";
import CreateFDRTemplateModal from "../../components/customFdrTemplate/CreateFDRTemplateModal";
import DeleteFdrTemplateModal from "../../components/customFdrTemplate/DeleteFdrTemplateModal";
import { MotifTabNavigation, MotifTabControl } from "@ey-xd/motif-react";
import { Link } from 'react-router-dom';
import { withRouter } from "../../common/withRouter";

/**
 * CustomFdrTemplateContainer container component
 * @extends {React.Component}
 */
export class CustomFdrTemplateContainer extends React.Component {
    /**
          * Creates a new CustomFdrTemplateContainer
          * @constructor
          * @param {Object} props The component properties
          * @param {Object} context The component context
          */
    constructor(props, context) {
        super(props, context);

        this.updateInitialValues = this.updateInitialValues.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSaveTemplate = this.handleSaveTemplate.bind(this);
        this.handleSaveAsTemplate = this.handleSaveAsTemplate.bind(this);
        this.handleTemplateSelection = this.handleTemplateSelection.bind(this);
        this.toggleShowCreateTemplateModal = this.toggleShowCreateTemplateModal.bind(this);
        this.clearDuplicateErrorMessage = this.clearDuplicateErrorMessage.bind(this);
        this.onEditorChanged = this.onEditorChanged.bind(this);
        this.setEditorReferences = this.setEditorReferences.bind(this);
        this.handleDeleteTemplate = this.handleDeleteTemplate.bind(this);
        this.toggleShowDeleteTemplateModal = this.toggleShowDeleteTemplateModal.bind(this);
        //this.preventSubmitOnEnter = this.preventSubmitOnEnter.bind(this);

        this.setTabBar1 = this.setTabBar1.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            showCreateTemplateModal: false,
            showDeleteTemplateModal: false,
            mode: 'create',
            editorUsed: false,
            editorReadOnly: true,
            initialHtml: '',
            tabBar1: 0,
            uniqueKey: 1
        };
    }

    componentDidMount() {
        //Fetch the templates for the dropdown
        this.props.actions.fetchCustomFdrTemplates(this.props.clientId).then(() => {
            this.props.dispatch(this.props.initialize({ templateId: -1 }));
        });
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.customFdrTemplateFormValues &&
            this.props.customFdrTemplateFormValues.templateId &&
            (!prevProps.customFdrTemplateFormValues ||
                this.props.customFdrTemplateFormValues.templateId !==
                prevProps.customFdrTemplateFormValues.templateId) &&
            this.props.customFdrTemplateFormValues.templateId !== -1
        ) {
            if (this.props.customFdrTemplateFormValues.templateId === -1) {
                this.setState({
                    editorUsed: false,
                    editorReadOnly: true,
                });
            } else {
                this.props.actions
                    .fetchCustomFdrTemplateById(
                        this.props.customFdrTemplateFormValues.templateId
                    )
                    .then((template) => {
                        // When we load a new template, set the initialHtml to be used for the defaultContent of the editor.
                        // Also, set uniqueKey to a new key, so the Editor component can re-render (to set initial state of editor, so undo/redo history is wiped clean)
                        this.setState({
                            initialHtml: template.fdrTemplateContent,
                            editorUsed: false,
                            editorReadOnly:
                                this.props.customFdrTemplateFormValues.templateId ===
                                -2,
                            uniqueKey: ++this.state.uniqueKey,
                        });
                    });
            }
        }
    }

    updateInitialValues(templateId, fdrTemplateName) {
        const values = Object.assign({}, {
            templateId,
            fdrTemplateName
        });

        this.props.dispatch(this.props.initialize(
            values,
            { options: { updateUnregisteredFields: true } }
        ));
    }

    //Set tab related state variables
    setTabBar1(currentIndex) {
        this.setState({ tabBar1: currentIndex });
    }

    //Set current tab index based
    handleClick(index) {
        this.setTabBar1(index);
    }

    handleCancel() {
           this.handleTemplateSelection(this.props.customFdrTemplateFormValues.templateId);       
       }
   
    handleSaveTemplate(values) {
        let templateId = values.templateId ? values.templateId : this.props.customFdrTemplateFormValues.templateId;

        this.props.actions.createOrEditFDRTemplate(this.props.clientId, templateId, values.fdrTemplateName, false, this.editor ? this.getHtml(this.editor.view.state) : null, this.props.customFdrTemplate).then((newTemplateId) => {
            if (newTemplateId && newTemplateId.response && newTemplateId.response.errorCode == 'DUPLICATE_RECORD_ERROR') {
                //Duplicate error
            }
            else {
                //If the values.templateId its equal to -1 it because is a new template otherwise is a current template to be modified
                if (values.templateId === -1) {
                    this.props.actions.fetchCustomFdrTemplates(this.props.clientId).then(() => {
                        this.toggleShowCreateTemplateModal();
                        this.props.dispatch(this.props.change('templateId', newTemplateId));
                    });
                }
                else {
                    // Do not reload data for existing template.
                    // We want to maintain the ability to undo changes after saving an existing template.
                    // Only set editorUsed to false to disabled Save/Cancel buttons
                    this.setState({
                        editorUsed: false,
                        showCreateTemplateModal: false                  
                    });
                }
            }      
        });
    }

    handleSaveAsTemplate(values) {
        let templateId = values.templateId ? values.templateId : this.props.customFdrTemplateFormValues.templateId;
        this.props.actions.SaveAsNewFDRTemplate(this.props.clientId, templateId, values.fdrTemplateName, this.getHtml(this.editor.view.state)).then((newTemplateId) => {
            if (newTemplateId && newTemplateId.response && newTemplateId.response.errorCode == 'DUPLICATE_RECORD_ERROR') {
                //Duplicate error
            }
            else {
                //If the values.templateId its equal to -1 it because is a new template otherwise is a current template to be modified
                this.props.actions.fetchCustomFdrTemplates(this.props.clientId).then(() => {
                    this.toggleShowCreateTemplateModal();
                    this.props.dispatch(this.props.change('templateId', newTemplateId));
                });

            }
        });
    }

    handleTemplateSelection(templateId) {
        // -1 is "Select an existing template"
        if (templateId === -1) {
            this.setState({
                editorUsed: false,
                editorReadOnly: true
            });
         } else {
            // Fetch the template contents (html)
            this.props.actions.fetchCustomFdrTemplateById(templateId).then(template => {
                // Update the initialHtml and uniqueKey.
                // If uniqueKey is updated, then the Editor component will re-render with new initialHtml, so that the undo/redo history is reset
                this.setState({
                    initialHtml: template.fdrTemplateContent,
                    editorUsed: false,
                    editorReadOnly: templateId === -2,
                    uniqueKey: ++this.state.uniqueKey             
                });            
            });
        }
    }

    clearDuplicateErrorMessage() {
        this.props.actions.clearDuplicateMessage();
    }

    toggleShowCreateTemplateModal(mode) {
        this.props.actions.clearDuplicateMessage();
        this.setState({
            showCreateTemplateModal: !this.state.showCreateTemplateModal,
            mode: mode       
        });
    }

    onEditorChanged(event) {
        this.setState({ editorUsed: true });
    }

    setEditorReferences(editor, setHtml, getHtml) {
        this.editor = editor;
        this.setHtml = setHtml;
        this.getHtml = getHtml;
    }

    handleDeleteTemplate() {
        this.props.actions.deleteFdrTemplateById(this.props.customFdrTemplateFormValues.templateId).then(() => {
            this.toggleShowDeleteTemplateModal();
            this.props.dispatch(this.props.change('templateId', -1));
        });
    }

    toggleShowDeleteTemplateModal() {
        this.setState({
            showDeleteTemplateModal: !this.state.showDeleteTemplateModal
        });
    }

    // preventSubmitOnEnter(e) {
    //     if (e.key === 'Enter') e.preventDefault();
    // }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <React.Fragment>
                <h2>Custom FDR Template</h2>
                <MotifTabNavigation variant="default">
                    <Link to={`/client/${this.props.clientId}/customFdrTemplate`}
                        tabIndex="-1">
                        <MotifTabControl className="motif-active"
                            autoFocus
                            role="tab"
                            onClick={() => this.handleClick(1)}>
                            Templates
                        </MotifTabControl>
                    </Link>
                    <Link to={`/client/${this.props.clientId}/customFdrTemplate/assignReits`} tabIndex="-1">
                        <MotifTabControl role="tab" onClick={() => this.handleClick(2)}>
                            Assign Defaults
                        </MotifTabControl>
                    </Link>
                </MotifTabNavigation>            
                <CustomFdrTemplateHeader
                    clientId={this.props.clientId}
                    customFdrTemplates={this.props.customFdrTemplate}
                    customFdrTemplateFormValues={this.props.customFdrTemplateFormValues}
                    onSubmit={this.handleSaveTemplate}
                    onCancel={this.handleCancel}
                    handleTemplateSelection={this.handleTemplateSelection}
                    editorUsed={this.state.editorUsed}
                    toggleShowCreateTemplateModal={this.toggleShowCreateTemplateModal}
                    toggleShowDeleteTemplateModal={this.toggleShowDeleteTemplateModal} />
                <div className="row">
                    <div className="col d-flex justify-content-center">
                        {this.props.customFdrTemplateFormValues && this.props.customFdrTemplateFormValues.templateId != -1 &&                          
                        <div className="enable-scroll">
                           <CustomFdrTemplateEditor
                                key={this.state.uniqueKey}
                                initialHtml={this.state.initialHtml}
                                onEditorChanged={this.onEditorChanged}
                                readOnly={this.state.editorReadOnly}
                                setEditorReferences={this.setEditorReferences}
                            />
                            </div>                          
                            }
                    </div>
                </div>

                {this.props.customFdrTemplateFormValues && this.props.customFdrTemplateFormValues.templateId !== -1 &&
                    <div className="mb-4">
                        {/* <Form onSubmit={this.props.handleSubmit(this.handleSaveTemplate)} onKeyPress={this.preventSubmitOnEnter}>
                            <div className="row">
                                <div className="col">
                                    <h6 className="mt-3">Questions in the Template</h6>
                                </div>
                                <div className="col">
                                    <div className="d-flex flex-row-reverse align-items-center mt-3">
                                        <button className="btn btn-primary ml-2" type="submit" disabled={this.props.invalid || this.props.submitting}>
                                            Save
                                        </button>
                                        <button className="btn btn-secondary" type="button" onClick={this.handleCancel} disabled={this.props.submitting}>
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form> */}
                    </div>}

                {this.state.showCreateTemplateModal &&
                    <CreateFDRTemplateModal
                        customFdrTemplateFormValues={this.props.customFdrTemplateFormValues}
                        customFdrTemplates={this.props.customFdrTemplate}
                        onSubmit={this.handleSaveTemplate}
                        onSaveAs={this.handleSaveAsTemplate}
                        toggleShowCreateTemplateModal={this.toggleShowCreateTemplateModal}
                        clearDuplicateErrorMessage={this.clearDuplicateErrorMessage}
                        mode={this.state.mode}
                    />
                }
                <DeleteFdrTemplateModal
                    showDeleteTemplateModal={this.state.showDeleteTemplateModal}
                    handleYes={this.handleDeleteTemplate}
                    toggleShowDeleteTemplateModal={this.toggleShowDeleteTemplateModal}
                />             
            </React.Fragment>
        );
    }
}

CustomFdrTemplateContainer.propTypes = {
    clientId: PropTypes.number,
    actions: PropTypes.object.isRequired,
    customFdrTemplateFormValues: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        clientId: Number.parseInt(ownProps.router.params.clientId),
        customFdrTemplate: state.customFdrTemplate,
        customFdrTemplateFormValues: getFormValues('customFdrTemplateForm')(state),
        customFdrTemplates: getFormValues('customFdrTemplateModalForm')(state)
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        reduxForm({ form: "customFdrTemplateForm", enableReinitialize: true })(CustomFdrTemplateContainer)
    )
);
