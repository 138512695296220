export const POLL_INTERVAL = process.env.POLL_INTERVAL;
export const NOTIFICATIONS_POLL_INTERVAL =
  process.env.NOTIFICATIONS_POLL_INTERVAL;
export const MAX_UPLOAD_PROCESSES = process.env.MAX_UPLOAD_PROCESSES;

export const FILE_PROPERTIES = [
  "lastModified",
  "lastModifiedDate",
  "name",
  "path",
  "preview",
  "size",
  "type",
  "webkitRelativePath",
];

export const RejectedEyDomain = "ey.com";
export const RejectedOtherEYDomain = ".ey.com";
export const prePopulateOptions = [
  { key: 0, text: "No / No", value: 2 },
  { key: 1, text: "No / Yes", value: 0 },
  { key: 2, text: "Yes / Yes", value: 1 },
];

export const deleteAvailableQuestionMessage = [
  "This will remove the ability to add this question to any template.",
  "This will also delete the question from other template(s) that's currently using it.",
  "This will not remove the question from an existing checklist that's currently using it.",
];

export const deleteAvailableParentQuestionMessage = [
  "This will delete the questions under this question.",
  "This will remove the ability to add this question/child questions to any template.",
  "This will also delete the question from other template(s) that's currently using it.",
  "This will not remove the question from an existing checklist that's currently using it.",
];

export const defaultPrepopulateAnswer = 2;
export const invalidEmailCreateChecklistHeader =
  "Information on Client Recipients";
export const invalidEmailAddUserHeader = "Information on Checklist Recipients";

export const rollForwardQuestionComment = [
  { key: 1, text: "No / No", value: 4 },
  { key: 2, text: "No / Yes", value: 1 },
  { key: 3, text: "Yes / No", value: 2 },
  { key: 4, text: "Yes / Yes", value: 3 },
];

export const reportPeriodQuarter = [
  { key: "Q1", text: "Q1", value: "Q1" },
  { key: "Q2", text: "Q2", value: "Q2" },
  { key: "Q3", text: "Q3", value: "Q3" },
  { key: "Q4", text: "Q4", value: "Q4" },
];

export const reportPackagesGroupBy = {
  1: "REIT",
  2: "REPORT_PERIOD",
  3: "FILE_TYPE",
};

export const reportPackagesGroupByValues = {
  1: "REIT",
  2: "Report Period",
  3: "Report Type",
};

export const reportPackagesFileType = {
  ConsolidatedMappings: 1,
  REITTrialBalance: 2,
  PropertyTrialBalance: 3,
  TestSnapshotFileperREIT: 4,
  TestSnapshotSingleExcelforselectedREITs: 5,
  PSQChecklistsWithAnswersOnlyMSWord: 6,
  PSQChecklistsWithAnswersOnlyPDF: 7,
  PSQChecklistsWithAnswersandCommentsMSWord: 8,
  PSQChecklistsWithAnswersandCommentsPDF: 9,
  PSQChecklistsWithoutAnswersorCommentsMSWord: 10,
  PSQChecklistsWithoutAnswersorCommentsPDF: 11,
  PSQChecklistsIncludeChecklistAttachmentsasSeparateFiles: 12,
  ARQChecklistsWithAnswersOnlyMSWord: 13,
  ARQChecklistsWithAnswersOnlyPDF: 14,
  ARQChecklistsWithAnswersandCommentsMSWord: 15,
  ARQChecklistsWithAnswersandCommentsPDF: 16,
  ARQChecklistsWithoutAnswersorCommentsMSWord: 17,
  ARQChecklistsWithoutAnswersorCommentsPDF: 18,
  ARQChecklistsIncludeChecklistAttachmentsasSeparateFiles: 19,
  FinalDeliveryReportStandardTemplateMSWord: 20,
  FinalDeliveryReportStandardTemplatePDF: 21,
  FinalDeliveryReportCustomTemplateMSWord: 22,
  FinalDeliveryReportCustomTemplatePDF: 23,
  FinalDeliveryReportAssignedDefaultsMSWord: 24,
  FinalDeliveryReportAssignedDefaultsPDF: 25,
  GeneralandOtherFiles: 26,
  UserUploadedFinalReport: 27,
  LeadSheet: 28,
  REITGeneralFiles: 29,
};

export const defaultRollFowardQuestionComment = 0;
export const defaultRollFowardQuestionAttachment = 0;

export const defaultRollFowardValue = 4;

export const MAX_UPLOAD_FILE_SIZE_IN_BYTES = 104857600;
export const MAX_UPLOAD_FILE_SIZE_IN_MB =
  MAX_UPLOAD_FILE_SIZE_IN_BYTES / (1024 * 1024);

export const bulkProcessManagementMessages = {
  toolTipMessage:
    "You have not selected any REIT(s) in order to perform this action.",
};

export const updateSignOffStatusAction = [
  {
    key: "closeTesting",
    text: "Close Testing",
    value: "2",
    action: "TestingClosed",
    type: "close",
  },
  {
    key: "closeReporting",
    text: "Close Reporting",
    value: "3",
    action: "ReportingClosed",
    type: "close",
  },
  {
    key: "closePeriod",
    text: "Close Period",
    value: "4",
    action: "Complete",
    type: "close",
  },
  {
    key: "reopenPeriod",
    text: "Reopen Period",
    value: "5",
    action: "ReportingClosed",
    type: "reopen",
  },
  {
    key: "reopenToReporting",
    text: "Reopen to Reporting",
    value: "6",
    action: "TestingClosed",
    type: "reopen",
  },
  {
    key: "reopenToTesting",
    text: "Reopen to Testing",
    value: "7",
    action: "InProgress",
    type: "reopen",
  },
];
export const defaultSelectedServiceScope = ["Asset Testing"];
export const emptySelectedServiceScope = ["None"];
export const dataRetentionNoPermissionMessage ='You do not have the required permissions to perform this action.';
export const dataRetentionNoRowSelectionMessage= 'You have not selected any REIT/Report Period to perform this action.';
export const dataRetentionMessageForApprove= 'The REIT/Report Period you have selected is not available for approval.';
export const dataRetentionMessageForUnApprove= 'The REIT/Report Period you have selected is not available for unapproval.';
export const dataRetentionMessageForException= 'The REIT/Report Period you have selected cannot be approved for exception.';
export const dataRetentionMessageForRemoveException= 'The REIT/Report Period you have selected is not approved for exception.';
export const deactivateClientConfirmationMessage="This client has report periods that have not been approved for deletion as per Data Retention Policy. Please approve these for deletion before proceeding with client inactivation.";
export const clientSwitchingConfirmationMessage="Changing the client type will update the data retention period to reflect the new type (2 years for Audit, 5 years for Tax). The scheduled deletion date for this client's data will be reset. Do you want to proceed with changing the client type?";
export const clientSwitchingErrorMessage="Client type cannot be changed. This client is currently in the data deletion cycle.";

export const dataRetentionManagementApprovalCompletedHeader= 'Approvals Completed';
export const dataRetentionManagementApprovalCompletedBodyOne= 'All required approvals for data deletion have been successfully provided. You now have full access to the client details as regularly.';
export const dataRetentionManagementApprovalCompletedBodyTwo= 'You are being redirected to the REITs dashboard for the client.';

export const dataRetentionManagementApprovedActionId = 1;
export const dataRetentionManagementApproveActionId = 4;
export const dataRetentionManagementUnApproveActionId = 5;
export const dataRetentionManagementApproveExceptionActionId = 7;
export const dataRetentionManagementRemoveExceptionActionId = 8;
export const dataStewardAssignmentWarningHeader='Data Steward Assignment Required';
export const dataStewardAssignmentWarningBody = 'Warning: Access Restricted! There is currently no Data Steward assigned to this client. Please notify an Engagement Admin to appoint a Data Steward. Your access to this client\'s info will be limited until the assignment is complete.';
export const dataStewardDeletionWarningHeader='Data Steward Assignment Required';
export const dataStewardDeletionWarningBody = 'Warning: Action Blocked! This action cannot be completed because it would result in no Data Steward assigned to this client. As per Data Retention policy, it is mandatory for a client to always have at least one Data Steward. Please assign a Data Steward before attempting this action again.';

export const DataPurgeConfigurationParameter_ARQC= 'Data Retention Period for Audit - ARQC Checklist Only Clients';
export const DataPurgeConfigurationParameter_Regular = 'Data Retention Period for Regular Clients';
export const dataDeletionNoRowSelectionMessage= 'Please select at least one record from the table to enable the \'Run Data Deletion\' button.';
export const maximumNumberofDataStewardsAllowed= 'Maximum Number of Data Stewards Allowed';
export const DEFAULT_DOCUMENT_TRANSFER_REMINDER_START_DATE = '01-01';
export const DEFAULT_DOCUMENT_TRANSFER_REMINDER_END_DATE = '09-30';
export const DEFAULT_UPCOMING_DATA_DELETION_NOTICE_START_DATE = '01-01';
export const DEFAULT_UPCOMING_DATA_DELETION_NOTICE_END_DATE_ADDNL_DAYS = 0;
export const REMINDER_TO_SAVE_FILE_OUTSIDE = 'Reminder to save files outside of REITSuite';
export const UPCOMING_DATA_DELETION_NOTICE = 'Upcoming Data Deletion Notice';
export const SUB_ATTRIBUTE = 'AO300';  // dummy valuers needs to be updated once, all database change has been completed
export const REMINDER_START_DATE_PARAMETER = 'Reminder to save files outside of REITSuite - Start Date (MM-DD)';
export const REMINDER_END_DATE_PARAMETER = 'Reminder to save files outside of REITSuite - End Date (MM-DD)';
export const UPCOMING_DATA_DELETION_NOTICE_START_DATE_PARAMETER = 'Upcoming Data Deletion Notice - Start Date (MM-DD)';
export const UPCOMING_DATA_DELETION_NOTICE_END_DATE_PARAMETER = 'Upcoming Data Deletion Notice - End Date - Additional days after Scheduled Deletion Date';
export const DELETION_STATUSES = {
  APPROVED: 'Approved',
  EXCEPTION: 'Exception',
  IN_PROGRESS: 'In Progress',
  DELETED: 'Deleted',
  FAILED: 'Failed'
};

