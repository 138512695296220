import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from "./actionTypes";
import { fetchFromClientApi as fetch, fetchFromReitApi } from "./fetchFromApi";
import * as actionHelpers from '../scripts/actionHelpers';
import * as authActions from './authActions';
import * as clearActions from './clearDependentsActions';

// Note on getting clients belonging to the employee that is logged in:
// The way I envision this working is that we would pass the JWT token in the
// header of all requests.  The ClientAPI would be able to retrieve the employee ID
// from the token and return the appropriate clients.
/**
 * Fetch the clients.  If successful this will dispatch the LOAD_CLIENTS_SUCCESS action,
 * otherwise it will dispatch the LOAD_CLIENTS_FAILURE action.
 * @param {boolean} [excludeInactive=false] - Set to true to include inactive clients.
 * @returns {function} a function which returns a Promise.
 */
export function loadAllClients(excludeInactive = false) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch("/clients").then(response => {
            return response.json();
        }).then(clientData => {
            if (actionHelpers.isErrorResponse(clientData)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CLIENTS_FAILURE, clientData);
            }

            return fetchFromReitApi("/reits").then(response => {
                return response.json();
            }).then(reitData => {
                if (actionHelpers.isErrorResponse(reitData)) {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CLIENTS_FAILURE, reitData);
                }

                if (Array.isArray(clientData) && Array.isArray(reitData)) {
                    for (let i = 0; i < clientData.length; i++) {
                        const clientReits = reitData.filter(x => Array.isArray(x.clients) &&
                            x.clients.findIndex(c => c.id === clientData[i].id) >= 0);

                        clientData[i].reitCount = clientReits.length;

                        // get sum of discrepancy message counts across child reits
                        clientData[i].discrepancyCount = clientReits.reduce((total, num) => {
                            return total + num.discrepancyCount;
                        }, 0);

                        let periods = [];

                        for (let r = 0; r < clientReits.length; r++) {
                            if (Array.isArray(clientReits[r].periods)) {
                                periods = periods.concat(clientReits[r].periods);
                            }
                        }

                        const today = new Date();
                        const year = today.getFullYear();
                        const q1TestDate = new Date(year, 1, 15);
                        const q2TestDate = new Date(year, 4, 15);
                        const q3TestDate = new Date(year, 7, 15);
                        const q4TestDate = new Date(year, 10, 15);

                        clientData[i].q1Status = getCombinedStatusFromPeriods(periods.filter(x => x.startDate &&
                            x.endDate &&
                            x.reportPeriodTypeDescription &&
                            x.reportPeriodTypeDescription.toLowerCase() === "quarterly" &&
                            q1TestDate <= new Date(x.endDate) &&
                            q1TestDate >= new Date(x.startDate)));

                        clientData[i].q2Status = getCombinedStatusFromPeriods(periods.filter(x => x.startDate &&
                            x.endDate &&
                            x.reportPeriodTypeDescription &&
                            x.reportPeriodTypeDescription.toLowerCase() === "quarterly" &&
                            q2TestDate <= new Date(x.endDate) &&
                            q2TestDate >= new Date(x.startDate)));

                        clientData[i].q3Status = getCombinedStatusFromPeriods(periods.filter(x => x.startDate &&
                            x.endDate &&
                            x.reportPeriodTypeDescription &&
                            x.reportPeriodTypeDescription.toLowerCase() === "quarterly" &&
                            q3TestDate <= new Date(x.endDate) &&
                            q3TestDate >= new Date(x.startDate)));

                        clientData[i].q4Status = getCombinedStatusFromPeriods(periods.filter(x => x.startDate &&
                            x.endDate &&
                            x.reportPeriodTypeDescription &&
                            x.reportPeriodTypeDescription.toLowerCase() === "quarterly" &&
                            q4TestDate <= new Date(x.endDate) &&
                            q4TestDate >= new Date(x.startDate)));
                    }
                }
                dispatch({ type: actions.LOAD_CLIENTS_SUCCESS, clients: clientData, [pendingTask]: end });
            });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CLIENTS_FAILURE, null, error);
        });
    };
}

/**
 * Retrieves a client by ID.
 * @param {any} clientId The ID of the client to retrieve.
 * @returns {any} A Promise.
 */
export function fetchClient(clientId) {
    return fetch(`/clients/${clientId}`).then(response => {
        return response.json();
    });
}

/**
 * Fetch the client wtih the matching clientId.  If successful this will dispatch the
 * LOAD_CLIENT_SUCCESS action, otherwise it will dispatch the LOAD_CLIENT_FAILURE action.
 * @param {number} clientId - The id of the client.
 * @returns {function} a function which returns a Promise.
 */
export function loadClient(clientId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetchClient(clientId).then(data => {
            if (actionHelpers.isErrorResponse(data)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CLIENT_FAILURE, data);
            }

            clearActions.clearReit(dispatch);
            dispatch({ type: actions.LOAD_CLIENT_SUCCESS, client: data, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CLIENT_FAILURE, null, error);
        });
    };
}

/**
 * Fetch the client with the matching clientId for External user.  If successful this will dispatch the
 * LOAD_CLIENT_SUCCESS action, otherwise it will dispatch the LOAD_CLIENT_FAILURE action.
 * @param {number} clientId - The id of the client.
 * @returns {function} a function which returns a Promise.
 */
export function loadExternalClient(clientId, checklistId, checklistType) {
    const authHeader = actionHelpers.buildAuthHeader(checklistId, checklistType);
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/clients/${clientId}`, {
            headers: authHeader && new Headers({ 'Authorization': authHeader })
        }).then(response => {
            return response.json();
        }).then(data => {
            if (actionHelpers.isErrorResponse(data)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CLIENT_FAILURE, data);
            }

            clearActions.clearReit(dispatch);
            dispatch({ type: actions.LOAD_CLIENT_SUCCESS, client: data, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CLIENT_FAILURE, null, error);
        });
    };
}

/**
 * Unloads the client from state.
 * @returns {any} A function that dispatches and action.
 */
export function unloadClient() {
    return function (dispatch) {
        clearActions.clearClient(dispatch);
    };
}

/**
 * Fetch the active clients.  This is the same as calling {@link loadAllClients}(true).
 * @returns {function} a function which returns a Promise.
 */
function loadActiveClients() {
    return loadAllClients(true);
}

// TODO - Wrap in Transaction - Create Client and Add Authorization for Engagement Administrator
/**
 * Create a client
 * @param {any} client The client to create.
 * @returns {any} A Promise
 */
export function createClient(client) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch("/clients",
            {
                headers: {
                    'Accept': "application/json",
                    'Content-Type': "application/json"
                },
                method: "POST",
                body: JSON.stringify(client)
            }).then(response => {
                return response.json();
            }).then(clientResponse => {
                if (actionHelpers.isErrorResponse(clientResponse)) {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CLIENT_FAILURE, clientResponse);
                }
                // Check for Create Client Errors -
                // TODO Determine Error messaging
                // Add Engagement Administrator to client
                let auth = {
                    "userId": client.engagementAdministratorEmail, "resource": authActions.resource.Client, "role": authActions.role.Administrator, "resourceId": clientResponse.id, "id": null,receiveDailyNewsletter:false,receiveWeeklyNewsletter:false,receiveRealTimeNotification:false, isDataSteward:false,
                };

                authActions.createAuthorization(auth)(dispatch);
                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                return clientResponse;
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CLIENT_FAILURE, null, error);
            });
    }
}
/** Update a client
 * @param { any } client The client
 * @returns { Promise } A Promise
*/
export function updateClient(client) {
    const payload = {
        "isActive": client.isActive,
        "isAudit": client.isAudit,
        "contactEmail": client.contactEmail,
        "contactName": client.contactName,
        "contactPhone": client.contactPhone,
        "engagementNumber": client.engagementNumber,
        "gfisCode": client.gfisCode,
        "id": client.id,
        "legalName": client.legalName,
        "name": client.name,
        "reitIds": client.reitIds
    };

    return fetch("/clients",
        {
            headers: {
                'Accept': "application/json",
                'Content-Type': "application/json"
            },
            method: "PUT",
            body: JSON.stringify(payload)
        }).then(response => {
            return response.json();
        });
}

/**
 * Update PSQ Completion Requirement status.
 * If successful this will dispatch the UPDATE_PSQ_COMPLETION_REQ_STATUS_SUCCESS action,
 * otherwise it will dispatch the UPDATE_PSQ_COMPLETION_REQ_STATUS_FAILURE action.
 * @param {any} clientId The ID of the client. 
 * @param {any} psqCompletionRequirementStatus The PSQ Completion Requirement status flag.
 * @returns {any} A function that returns a Promise.
 */
export function updatePSQCompletionRequirementStatus(
    clientId, psqCompletionRequirementStatus
) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(`/clients/psqCompletionRequirement/${clientId}/${psqCompletionRequirementStatus}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'PUT'
        }).then(response => {
            return response.text();
        }).then(response => {
            const jsonResponse = response ? JSON.parse(response) : {};

            if (actionHelpers.isErrorResponse(jsonResponse)) {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.UPDATE_PSQ_COMPLETION_REQ_STATUS_FAILURE,
                    jsonResponse,
                );
            }
            dispatch({ type: actions.TASK_END, [pendingTask]: end });

            return jsonResponse;
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(
                dispatch,
                actions.UPDATE_PSQ_COMPLETION_REQ_STATUS_FAILURE,
                null,
                error,
            );
        });
    };
}

/**
 * Update IsLockedForMissingDeletionApprovalOrException status.
 * If successful this will dispatch the UPDATE_IS_LOCKED_FOR_MISSING_DELETION_APPROVAL_OR_EXCEPTION_STATUS_SUCCESS action,
 * otherwise it will dispatch the UPDATE_IS_LOCKED_FOR_MISSING_DELETION_APPROVAL_OR_EXCEPTION_STATUS_FAILURE action.
 * @param {any} clientId The ID of the client. 
 * @returns {any} A function that returns a Promise.
 */
export function updateIsLockedForMissingDeletionApprovalOrExceptionStatus(
    clientId
) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(`/clients/missingDeletionApprovalOrExceptionRequirement/${clientId}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'PUT'
        }).then(response => {
            return response.text();
        }).then(response => {
            const jsonResponse = response ? JSON.parse(response) : {};

            if (actionHelpers.isErrorResponse(jsonResponse)) {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.UPDATE_IS_LOCKED_FOR_MISSING_DELETION_APPROVAL_OR_EXCEPTION_STATUS_FAILURE,
                    jsonResponse,
                );
            }
            dispatch({ type: actions.TASK_END, [pendingTask]: end });

            return jsonResponse;
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(
                dispatch,
                actions.UPDATE_IS_LOCKED_FOR_MISSING_DELETION_APPROVAL_OR_EXCEPTION_STATUS_FAILURE,
                null,
                error,
            );
        });
    };
}

/**
 * Toggle the isActive property on the client.
 * @param {any} clientId The ID of the client.
 * @returns {any} A function that returns a Promise.
 */
export function toggleActiveFlagAndReloadClients(clientId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetchClient(clientId).then(client => {

            if (actionHelpers.isErrorResponse(client)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.TOGGLE_CLIENT_ACTIVE_FLAG_FAILURE, client);
            }

            client.isActive = !client.isActive;
            return updateClient(client);
        }).then(data => {

            if (actionHelpers.isErrorResponse(data)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.TOGGLE_CLIENT_ACTIVE_FLAG_FAILURE, data);
            }

            loadAllClients()(dispatch);
            dispatch({ type: actions.TASK_END, [pendingTask]: end });
        });
    };
}

/**
 * Determine the collective status by looking at the status of all periods.
 * @param {any} periods The periods to look at.
 * @returns {string} The status.
 */
function getCombinedStatusFromPeriods(periods) {
    if (!Array.isArray(periods) || periods.length === 0) {
        return "Not Started";
    }

    let notStarted = 0;
    let planned = 0;
    let inProgress = 0;
    let complete = 0;

    const periodCount = periods.length;
    for (let i = 0; i < periodCount; i++) {
        switch (periods[i].reportPeriodStatusDescription) {
            case "Complete":
                complete++;
                break;
            case "In Progress":
            case "Closed to Testing":
            case "Closed to Reporting":
                inProgress++;
                break;
            case "Planned":
                planned++;
                break;
            default:
                notStarted++;
        }
    }

    if (complete === periodCount) {
        return "Complete";
    }

    if (notStarted === periodCount) {
        return "Not Started";
    }

    if (inProgress === 0 && planned > 0) {
        return "Planned";
    }

    return "In Progress";
}

//This is in Client API, because our email service is here.
/**
 * Send an email to all users and return the response.
 * @param {string} subject The email subject.
 * @param {string} message The email message.
 * @param {bool} isHtml True if the email message contains HTML, otherwise false.
 * @returns {any} A Promise.
 */
export function contactAllUsers(subject, message, isHtml) {
    let request = {
        "subject": subject,
        "message": message,
        "isHtml": isHtml
    };
    return fetch('/email/contactall',
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(request)
        }).then(response => {
            if (response.ok) {
                return null;
            } else {
                return response.json();
            }
        });
}

/**
 * Retrieves a client by ID.
 * @param {any} clientId The ID of the client to retrieve.
 * @returns {any} A Promise.
 */
export function fetchAllClientsSlim() {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch("/clients/names").then(response => {
            return response.json();
        }).then(clientData => {
            if (actionHelpers.isErrorResponse(clientData)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CLIENTS_FAILURE, clientData);
            }
            dispatch({ type: actions.LOAD_CLIENTS_SUCCESS, clients: clientData, [pendingTask]: end });
        });
    };
}

/**
 * Retrieves list of active clients. Currently being used to populate the client dropdown in the External Checklists Dashboard.
 * @returns {any} A Promise.
 */

export function fetchAllActiveClients(resetState = false) {
    return (dispatch) => {
        if (resetState === true) {
            return dispatch({
                type: actions.LOAD_EXTERNAL_CHECKLISTS_CLIENTS_SUCCESS,
                clients: []
            });
        }

        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin});        
        return fetch("/clients")
            .then(response => response.json())
            .then(clientsData => {
                if(actionHelpers.isErrorResponse(clientsData)) {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_EXTERNAL_CHECKLISTS_CLIENTS_FAILURE, clientsData);
                }
                const activeClients = clientsData.filter(c => c.isActive);
                dispatch({type: actions.LOAD_EXTERNAL_CHECKLISTS_CLIENTS_SUCCESS, clients: activeClients, [pendingTask]: end});
            })
    }
}

/**
* To switch the client type.
* @returns {any} A Promise.
*/

export function switchClientType(request) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(`/clients/switchClientType`,
            {
                headers: {
                    'Accept':
                        "application/json",
                    'Content-Type': "application/json"
                },
                method: "POST",
                body: JSON.stringify(request)
            }).then(response => {
                return response.json();
            }).then(result => {
                if (actionHelpers.isErrorResponse(result)) {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.CREATE_CLIENT_FAILURE, result);
                }
                dispatch({ type: actions.CREATE_CLIENT_SUCCESS, result, [pendingTask]: end });
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.CREATE_CLIENT_FAILURE, error);
            });
    };
}

export function checkIfClientCanSwitchType(clientId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin});
        return fetch(`/clients/checkIfClientCanSwitchType/${clientId}`).then(response => {
            return response.json();
        }).then(canSwitchType => {
            if (actionHelpers.isErrorResponse(canSwitchType)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CAN_SWITCH_TYPE_FAILURE, null);
            }
            dispatch({ type: actions.LOAD_CAN_SWITCH_TYPE_SUCCESS, canSwitchType, [pendingTask]: end });
            return canSwitchType;
        });
    };
}



