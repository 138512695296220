import * as actions from "../actions/actionTypes";
import initialState from "./initialState";

/**
 * This reducer handles the GET_INCOMPLETE_SECURITIES_SNAPSHOTS_SUCCESS and GET_INCOMPLETE_SECURITIES_SNAPSHOTS_FAILURE actions and sets the incompleteSecuritiesSnapshots in the store.
 * @param {Object} [state] The incompleteSecuritiesSnapshots currently in the store, or the initial state of the incompleteSecuritiesSnapshots.
 * @param {Object} action The action to be handled.
 * @returns {Object} The updated state of incompleteSecuritiesSnapshots.
 */
export default function incompleteSecuritiesSnapshotsReducer(state = initialState.incompleteSecuritiesSnapshots, action) {
    switch (action.type) {
        case actions.GET_INCOMPLETE_SECURITIES_SNAPSHOTS_SUCCESS:
            return action.incompleteSecuritiesSnapshots;
        case actions.GET_INCOMPLETE_SECURITIES_SNAPSHOTS_FAILURE:
            return initialState.incompleteSecuritiesSnapshots;
        case actions.RELOAD_INCOMPLETE_SECURITIES_SNAPSHOTS_SUCCESS:
            return action.incompleteSecuritiesSnapshots;
           
        default:
            return state;
    }
}
