import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SubmissionError, reset, getFormValues } from "redux-form";
import EditClientComponent from "../../components/clients/EditClientComponent";
import * as clientActions from "../../actions/clientActions";
import * as reitActions from "../../actions/reitActions";
import * as dataPurgeActions from "../../actions/dataPurgeActions";
import * as propertyActions from "../../actions/propertyActions";
import * as periodActions from "../../actions/periodActions";
import * as formHelpers from "../../scripts/formHelpers";
import * as actionHelpers from "../../scripts/actionHelpers";
import * as dateHelpers from "../../scripts/dateHelpers";
import * as authActions from "../../actions/authActions";
import * as commonActions from "../../actions/commonActions";
import * as constants from '../../constants/other';
import ReactivateClientModal from "../../components/clients/ReactivateClientModal";
import ClientTypeSwitchingConfirmationMessageModal from "../../components/clients/ClientTypeSwitchingConfirmationMessageModal";
import ClientTypeSwitchingErrorMessageModal from "../../components/clients/ClientTypeSwitchingErrorMessageModal";
import DeleteModal from "../../components/shared/DeleteModal";
import PropertyAmenitiesTableContainer from "../../containers/shared/PropertyAmenitiesTableContainer";
import * as authorizationDomainValidator from "../../scripts/authorizationDomainValidator";
import { store } from "../../store/configureStore";
import {
    MotifButton,
    MotifModal,
    MotifModalHeader,
    MotifIcon,
    MotifModalBody,
    MotifModalFooter,
    MotifToast
} from "@ey-xd/motif-react";
import { ToastContainer, toast, Slide } from 'react-toastify';
import { IconoirWarningCircle } from '@ey-xd/motif-icon';
import { withRouter } from "../../common/withRouter";
import DataStewardAssignmentRequiredModal from '../../components/dataStewardRoleManagement/dataStewardAssignmentRequiredModal';
import * as Constants from '../../constants/other';
import DeactivateClientDisplayErrorMessageModal from "../../components/clients/DeactivateClientDisplayErrorMessageModal";

/**
 * The ClientPage class
 * @extends {React.Component}
 */
export class EditClientPage extends React.Component {
    /**
     * Creates a new EditClientPage
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = {
            showReitForm: false,
            clientFormPurpose: "",
            reitFormPurpose: "",
            selectedReit: {
                reitid: 0,
                reitName: "",
                reitShortName: "",
                reitTypeID: "",
                numberOfSharesClasses: 0,
                preferredNumberOfShareHolders: 0,
                StateOrganized: "",
                Symbol: "",
                BalanceThreshold: 0,
                PercentageThreshold: 0,
                ein: "",
                organizationTypeID: "",
                periodQuarter: dateHelpers.getQuarter(),
                periodYear: dateHelpers.getYear(),
                showMinimumDataStewardWarningModal: false,
                showDeactivateErrorMessageModal: false,
                showClientSwitchingMessageModal: false,
                showClientSwitchingErrorMessageModal: false,
            },
            showReitSuccess: false,
            maximumDataStewardReachedMessage: false,
            filteredClientAuths: [],
            showAddClientAuthModal: false,
            showEditClientAuthModal: false,
            showMyEyOnboardingModal: false,
            auth: { "userId": null, "resource": authActions.resource.Client, "role": "2", "resourceId": this.props.clientId, "id": null, receiveDailyNewsletter: false, receiveWeeklyNewsletter: false, receiveRealTimeNotification: false, receiveRealTimeNotificationForNewPsqArqcSent: false, isDataSteward: false },
            confirmAction: null,
            clientUserSearchText: "",
            currentUserId: this.props.currentUserId,
            currentUserIsSysAdmin: this.props.currentUserIsSysAdmin,
            addAuthMessageText: null,
            maxLimitMessageText: null,
            hideSuccessMessage: false,
            hideReitSuccessMessage: false,
            showReactivateClientModal: false,
            clientData: {},
            isActive: true,
            isAudit: false,
            numberOfEngagementAdmins: 0,
            numberOfDataStewards: 0,
            dataPurgeMaximumDSValue: 0,
            allowedDomains: "",
            myEyUser: {},
            receiveDailyNewsletter: false,
            receiveWeeklyNewsletter: false,
            receiveRealTimeNotificationForNewPsqArqcSent: false,
            isDataSteward: false,
            showDSError: false,
            dataStewardCheckboxDisplay: false,
            dataPurgeConfigurationData: [],
            deletedUserID: null,
            dataStewardValue: false,
            canSwitchType: false,
            isDisabledValue: !this.props.currentUserIsSysAdmin,
        };
        this.handleCloseReitForm = this.handleCloseReitForm.bind(this);
        this.showReitForm = this.showReitForm.bind(this);
        this.handleClientSubmit = this.handleClientSubmit.bind(this);
        this.updateClient = this.updateClient.bind(this);
        this.handleEditReitClick = this.handleEditReitClick.bind(this);
        this.handleReitSubmit = this.handleReitSubmit.bind(this);
        this.createProperty = this.createProperty.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleClearSearchText = this.handleClearSearchText.bind(this);
        this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
        this.handleNewButtonClick = this.handleNewButtonClick.bind(this);
        this.handleAddClientAuthModalAction = this.handleAddClientAuthModalAction.bind(this);
        this.handleAddClientAuthModalCancelAction = this.handleAddClientAuthModalCancelAction.bind(this);
        this.toggleShowAddClientAuthModal = this.toggleShowAddClientAuthModal.bind(this);
        this.toggleShowMyEyOnboardingModal = this.toggleShowMyEyOnboardingModal.bind(this);
        this.handleEditClientAuthModalAction = this.handleEditClientAuthModalAction.bind(this);
        this.handleEditClientAuthModalCancelAction = this.handleEditClientAuthModalCancelAction.bind(this);
        this.toggleShowEditClientAuthModal = this.toggleShowEditClientAuthModal.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.handleCancelDelete = this.handleCancelDelete.bind(this);
        this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
        this.handleDismissReitErrorMessage = this.handleDismissReitErrorMessage.bind(this);
        this.handleDismissSuccessMessage = this.handleDismissSuccessMessage.bind(this);
        this.handleDismissReitSuccessMessage = this.handleDismissReitSuccessMessage.bind(this);
        this.handleToggleActiveFlag = this.handleToggleActiveFlag.bind(this);
        this.handleToggleAuditFlag = this.handleToggleAuditFlag.bind(this);
        this.handleReactivateClient = this.handleReactivateClient.bind(this);
        this.handleDeactivateClient = this.handleDeactivateClient.bind(this);
        this.toggleShowReactivateClientModal = this.toggleShowReactivateClientModal.bind(this);
        this.toggleIsActive = this.toggleIsActive.bind(this);
        this.handleEditClientCancel = this.handleEditClientCancel.bind(this);
        this.redirectToPropertiesPage = this.redirectToPropertiesPage.bind(this);
        this.validateDomain = this.validateDomain.bind(this);
        this.validateDomainWithRole = this.validateDomainWithRole.bind(this);
        this.handleAddClientAuthModalValidateAction = this.handleAddClientAuthModalValidateAction.bind(this);
        this.handleDailyNewsletter = this.handleDailyNewsletter.bind(this);
        this.handleWeeklyNewsletter = this.handleWeeklyNewsletter.bind(this);
        this.handleRealTimeNotification = this.handleRealTimeNotification.bind(this);
        this.handleInternalReviewNotification = this.handleInternalReviewNotification.bind(this);
        this.handleOnRoleTypeChange = this.handleOnRoleTypeChange.bind(this);
        this.handleDataSteward = this.handleDataSteward.bind(this);
        this.handleDataStewardCheckboxDisplay = this.handleDataStewardCheckboxDisplay.bind(this);
        this.handleDataStewardsForClientCount = this.handleDataStewardsForClientCount.bind(this);
        this.toggleShowMinimumDataStewardWarningModal = this.toggleShowMinimumDataStewardWarningModal.bind(this);
        this.toggleShowDeactivateClientModal = this.toggleShowDeactivateClientModal.bind(this);
        this.toastSystem = React.createRef();
        this.toggleShowClientSwitchingModal = this.toggleShowClientSwitchingModal.bind(this);
        this.toggleShowClientSwitchingErrorModal = this.toggleShowClientSwitchingErrorModal.bind(this);
        this.handleClientSwitching = this.handleClientSwitching.bind(this);
    }

    /**
     * Invoked immediately after a component mounts
     */
    componentDidMount() {
        if (this.props.clientId) {
            this.props.actions.loadClient(this.props.clientId);
            this.props.reitActions.fetchReits(this.props.clientId);
            this.props.dataPurgeActions.fetchDataPurgeConfigurationData();
            this.props.reitActions.fetchReitLookupTypes();
            this.props.authorizationActions.fetchAuthorizations(null, 2, this.props.clientId, null);
            this.props.clientActions.checkIfClientCanSwitchType(this.props.clientId).then(
                (responseData) => {
                    this.setState({ canSwitchType: responseData })
                });
            this.setFilter();
        } else {
            this.props.actions.unloadClient();
        }
        authActions.fetchAllowedAuthorizationDomains().then((allowedDomains) => { this.setState({ allowedDomains: allowedDomains }); });
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.authorizations !== prevProps.authorizations && this.props.authorizations && prevProps.authorizations) {
            if (this.props.clientId) {
                const list = Object.assign([], this.props.authorizations);
                this.setState({ filteredClientAuths: list },
                    this.setState({
                        numberOfEngagementAdmins: this.props.authorizations.filter(x => x.resourceId === this.props.clientId.toString() && x.resource === 2 && x.role === 1).length,
                    })
                );
            }
        }

        if (prevState.numberOfDataStewards !== this.state.numberOfDataStewards) {
            if (this.state.numberOfDataStewards >= this.dataPurgeMaximumDSValue) {
                this.setState({ dataStewardCheckboxDisplay: true });
            }
        }

        if (this.props.dataPurgeConfigurationData != prevProps.dataPurgeConfigurationData) {
            this.getDataPurgeMaximumDSValue();
        }
        if (this.props.clientId !== prevProps.clientId) {
            this.props.actions.loadClient(this.props.clientId);
            this.props.dataPurgeActions.fetchDataPurgeConfigurationData();
            this.props.reitActions.fetchReits(this.props.clientId);
            this.props.authorizationActions.fetchAuthorizations(null, 2, this.props.clientId, null);

            this.setFilter();
        }

        if (this.props.client !== prevProps.client) {
            if (this.props.client !== null) {
                this.setState({ isActive: this.props.client.isActive, isAudit: this.props.client.isAudit });
                this.reloadAdminAuths(true);
                this.props.dataPurgeActions.fetchDataPurgeConfigurationData();


            }
        }
    }
    toggleShowDeactivateClientModal() {
        this.setState({ showDeactivateErrorMessageModal: false });
    }

    toggleShowClientSwitchingModal() {
        this.setState({ showClientSwitchingMessageModal: false });
    }

    toggleShowClientSwitchingErrorModal() {
        this.setState({ showClientSwitchingErrorMessageModal: false });
    }

    handleDismissErrorMessage() {
        this.props.commonActions.clearFormErrors('client');
    }

    handleDismissReitErrorMessage() {
        this.props.commonActions.clearFormErrors('reit');
    }

    handleDismissSuccessMessage() {
        this.setState({ hideSuccessMessage: true });
    }

    handleDismissReitSuccessMessage() {
        this.setState({ hideReitSuccessMessage: true });
    }

    reloadReits(reloadReits) {
        if (reloadReits) {
            this.props.reitActions.fetchReits(this.props.clientId);
        }
    }

    handleEditClientCancel() {
        this.props.router.navigate("/");
    }

    getDataPurgeMaximumDSValue() {
            const dataPurgeConfig = this.props.dataPurgeConfigurationData && this.props.dataPurgeConfigurationData.find(item => item.dataPurgeConfigurationID === 4);
            return this.dataPurgeMaximumDSValue = dataPurgeConfig ? dataPurgeConfig.dataPurgeConfigurationValue : null;
    }

    handleDataStewardCheckboxDisplay(numberOfDataStewards) {
        if (numberOfDataStewards >= this.dataPurgeMaximumDSValue) {
            this.setState({ dataStewardCheckboxDisplay: true });
        }
        else {
            this.setState({ dataStewardCheckboxDisplay: false });
        }
    }

    handleDataStewardsForClientCount() {
        const numberOfDataStewards = this.props.authorizations && this.props.authorizations.filter(x => x.resourceId === this.props.clientId.toString() && x.resource === 2 && x.isDataSteward === true).length;
        this.setState({
            numberOfDataStewards: numberOfDataStewards
        });
        this.handleDataStewardCheckboxDisplay(numberOfDataStewards);

    }

    handleClientSubmit(values) {
        this.setState({ hideSuccessMessage: false });
        const client = {
            id: this.props.clientId,
            legalName: values.legalName,
            name: values.name,
            gfisCode: values.gfisCode,
            engagementNumber: values.engagementNumber,
            isActive: this.state.isActive,
            isAudit: this.state.isAudit,
            contactName: values.contactName,
            contactPhone: values.contactPhone,
            contactEmail: values.contactEmail,
            engagementAdministratorEmail: values.engagementAdministratorEmail
        };

        if (client.id === 0 || isNaN(client.id)) {
            this.setState({ clientFormPurpose: "" });
            return this.createClient(client);
        }

        this.setState({ clientFormPurpose: "EDIT" });
        return this.updateClient(client);
    }

    createClient(client) {
        return this.props.clientActions.createClient(client).then(data => {
            this.setState({ reloadClients: true });
            if (data && data.id && !data.errorCode) { // As long as we don't get back data, or the data we get back doesn't have an errorCode, assume success
                this.props.router.navigate(`/client/${data.id}/edit?showSuccess=true`);
                return;
            }

            formHelpers.generateSubmissionError(data);
        }).catch(err => {
            if (err instanceof SubmissionError) {
                throw err;
            }

            formHelpers.generateSubmissionError();
        });
    }

    updateClient(client) {
        return clientActions.updateClient(client).then(data => {
            if (data && data.id && !data.errorCode) { // As long as we don't get back data, or the data we get back doesn't have an errorCode, assume success
                this.props.actions.loadClient(this.props.clientId);
                this.props.router.navigate(`/client/${data.id}/edit?showSuccess=true`);
                return;
            }

            formHelpers.generateSubmissionError(data);
        }).catch(err => {
            if (err instanceof SubmissionError) {
                throw err;
            }

            formHelpers.generateSubmissionError();
        });
    }

    handleReitSubmit(values, e) {

        this.setState({ hideReitSuccessMessage: false });

        let reitToHandle = Object.assign({}, values);
        if (values.ein && values.ein.indexOf("-") === -1) {
            reitToHandle.ein = `${values.ein.slice(0, 2)}-${values.ein.slice(2)}`;
        }
        if (this.state.reitFormPurpose === "EDIT") {
            return this.handleReitEditSubmit(reitToHandle);
        }

        return this.handleReitCreateSubmit(reitToHandle);
    }

    handleReitCreateSubmit(values) {
        this.props.commonActions.beginTask();

        this.setState({ showReitSuccess: false });
        if (this.props.clientId === 0) {
            this.props.commonActions.endTask();
            throw new SubmissionError({ _error: "A REIT must be associated with a client." });
        }

        const reit = {
            reitID: this.props.reitID,
            reitName: values.reitName,
            reitShortName: values.reitShortName,
            balanceThreshold: values.balanceThreshold,
            percentageThreshold: values.percentageThreshold,
            reitTypeID: values.reitTypeID || 0,
            numberOfSharesClasses: values.numberOfSharesClasses || 0,
            preferredNumberOfShareHolders: values.preferredNumberOfShareHolders || 0,
            stateOrganized: values.stateOrganized,
            clientId: this.props.clientId,
            dateOfElection: values.dateOfElection,
            symbol: values.symbol,
            ein: values.ein,
            organizationTypeID: values.organizationTypeID,
            formationDate: values.formationDate,
            periodQuarter: values.periodQuarter,
            periodYear: values.periodYear
        };

        const propertyAmenities = PropertyAmenitiesTableContainer.convertFromReduxFormValueToPropertyAmenityCollection(values.propertyAmenities, values.propertyId);

        const property = {
            id: this.props.propertyId,
            reportPeriodId: 1,
            //name: values.propertyName.trim(),
            propertyName: values.propertyName.trim(),
            locationAddressStreet: values.locationAddressStreet.trim(),
            locationAddressCity: values.locationAddressCity.trim(),
            locationAddressState: values.locationAddressState,
            locationAddressZipCode: values.locationAddressZipCode,
            propertyTypeID: values.propertyTypeID,
            psqPropertyTypes: values.psqPropertyTypes.map(p => p.replace("Multi-Family", "MultiFamily")),
            propertyClassID: values.propertyClassID,
            rentableSquareFootage: values.rentableSquareFootage,
            tenantTypeId: values.tenantTypeId,
            percentageOfOwnership: values.percentageOfOwnership,
            propertyParkingTypeID: values.propertyParkingTypeID,
            purchasePrice: values.purchasePrice,
            purchaseDate: values.purchaseDate,
            managementCompany: values.managementCompany.trim(),
            managerName: values.managerName.trim(),
            managerPhone: values.managerPhone,
            managerEmail: values.managerEmail.trim(),
            accountantName: values.accountantName.trim(),
            accountantEmail: values.accountantEmail.trim(),
            accountantPhone: values.accountantPhone,
            propertyAmenity: propertyAmenities
        };

        if (!property.rentableSquareFootage) {
            property.rentableSquareFootage = 0;
        }

        if (!property.purchasePrice) {
            property.purchasePrice = 0.00;
        }

        //Create Reit
        return reitActions.createReit(reit).then(data => {
            if (actionHelpers.isErrorResponse(data)) {
                this.props.commonActions.endTask();
                return formHelpers.generateSubmissionError(data);
            }

            //Create Property
            if (property.id === undefined || property.id === 0 || isNaN(property.id)) {
                const reitId = data.reitid;
                //add Report Period to Property
                property.reportPeriodId = periodActions.getCurrentPeriod(data).reportPeriodID;

                //Create Property
                return this.createProperty(property, reitId, values.anotherProperty === 'YES');
            } else {
                this.handleCloseReitForm();
                this.props.reitActions.fetchReits(this.props.clientId);
                this.setState({ showReitSuccess: true });
                this.props.commonActions.endTask();
            }
        });

    }

    handleReitEditSubmit(values) {
        this.props.commonActions.beginTask();

        this.setState({ showReitSuccess: false });
        if (this.props.clientId === 0) {
            throw new SubmissionError({ _error: "A REIT must be associated with a client." });
        }
        const reit = {
            reitID: values.reitid,
            reitName: values.reitName,
            reitShortName: values.reitShortName,
            balanceThreshold: values.balanceThreshold,
            dateOfElection: values.dateOfElection,
            percentageThreshold: values.percentageThreshold,
            reitTypeID: values.reitTypeID || 0,
            numberOfSharesClasses: values.numberOfSharesClasses || 0,
            preferredNumberOfShareHolders: values.preferredNumberOfShareHolders || 0,
            stateOrganized: values.stateOrganized,
            symbol: values.symbol,
            clientId: this.props.clientId,
            ein: values.ein,
            organizationTypeID: values.organizationTypeID,
            formationDate: values.formationDate
        };

        return this.updateReit(reit);
    }

    updateReit(reit) {
        return reitActions.updateReit(reit).then(data => {
            if (actionHelpers.isErrorResponse(data)) {
                this.props.commonActions.endTask();
                return formHelpers.generateSubmissionError(data);
            }
            this.handleCloseReitForm();
            this.props.reitActions.fetchReits(this.props.clientId);
            this.setState({ showReitSuccess: true });
            this.props.commonActions.endTask();
            return;

        }).catch(err => {
            if (err instanceof SubmissionError) {
                throw err;
            }

            this.props.commonActions.endTask();
            formHelpers.generateSubmissionError();
        });
    }

    createProperty(property, reitId, addMoreProperties) {
        return propertyActions.createProperty(property).then(data => {
            if (data && data.propertyID && !data.errorCode) { // As long as we don't get back data, or the data we get back doesn't have an errorCode, assume success

                if (addMoreProperties && reitId) {
                    this.props.commonActions.endTask();
                    this.redirectToPropertiesPage(reitId, property.reportPeriodId);
                    return;
                }

                //Close call modal
                this.handleCloseReitForm();

                //refresh list
                this.props.reitActions.fetchReits(this.props.clientId);

                this.setState({ showReitSuccess: true });
                this.props.commonActions.endTask();

                return;
            }

            if (data && Array.isArray(data.validationErrors)) {
                for (let index = 0; index < data.validationErrors.length; index++) {
                    if (data.validationErrors[index].element.toLowerCase() === "name") {
                        data.validationErrors[index].element = "propertyName";
                    }
                }
            }

            this.props.commonActions.endTask();
            return formHelpers.generateSubmissionError(data);
        }).catch(err => {
            if (err instanceof SubmissionError) {
                this.props.commonActions.endTask();
                throw err;
            }

            this.props.commonActions.endTask();
            formHelpers.generateSubmissionError();
        });
    }

    showReitForm(e) {
        e.preventDefault();
        this.props.clientActions.loadClient(this.props.clientId).then(() => {
                           this.setState({ isAudit: this.props.client.isAudit});
                           this.setState({ showReitForm: true, reitFormPurpose: "", selectedReit: null });
                        });        
    }

    handleCloseReitForm() {
        this.setState({ showReitForm: false });
    }

    handleEditReitClick(values) {

        const reit = {
            reitid: values.reitid,
            reitName: values.reitName,
            reitShortName: values.reitShortName,
            balanceThreshold: values.balanceThreshold,
            dateOfElection: values.dateOfElection,
            percentageThreshold: values.percentageThreshold,
            reitTypeID: values.reitTypeID || 0,
            numberOfSharesClasses: values.numberOfSharesClasses || 0,
            preferredNumberOfShareHolders: values.preferredNumberOfShareHolders || 0,
            stateOrganized: values.stateOrganized,
            symbol: values.symbol,
            clientId: values.clientId,
            ein: values.ein,
            organizationTypeID: values.organizationTypeID,
            formationDate: values.formationDate
        };

        this.setState({ showReitForm: true, reitFormPurpose: "EDIT", selectedReit: reit });
    }

    handleDeleteReitClick(reitId) {
        // Not yet supported.
    }

    handleSearchChange(e) {
        this.setState({ clientUserSearchText: e.target.value }, this.setFilter);
    }

    handleClearSearchText(e) {
        this.setState({ clientUserSearchText: "" }, this.setFilter);
    }

    handleDeleteButtonClick(auth) {
        if (auth.isDataSteward && this.props.authorizations && this.props.authorizations.filter(x => x.isDataSteward).length === 1) {
            this.toggleShowMinimumDataStewardWarningModal();
            return;
        }

        let id = auth.id;
        this.deletedUserID = auth.userId;
        this.setState({
            confirmAction: () => this.props.authActions.removeAuthorization(id).then(() => {
                this.reloadAdminAuths(true);
                this.setState({ auth: { "userId": null, "resource": authActions.resource.Client, "role": "2", "resourceId": this.props.clientId, "id": null, receiveDailyNewsletter: false, receiveWeeklyNewsletter: false } });
            })
        });
    }

    handleNewButtonClick(e) {
        e.preventDefault();
        this.toggleShowAddClientAuthModal();
        this.handleDataStewardsForClientCount();
    }

    handleAddClientAuthModalAction(values) {
        if (!values || !values.userId || values.userId.trim() === "") {
            this.setState({ addAuthMessageText: "Required" });
            return;
        }

        for (let auth of this.props.authorizations) {
            if (values.userId.toLowerCase() === auth.userId.toLowerCase()) {
                this.setState({ addAuthMessageText: `'${values.userId}' is already an authorized user.` });
                return;
            }
        }

        let authValues = Object.assign({}, values);
        this.handleDataStewardsForClientCount();
        authValues.receiveDailyNewsletter = this.state.receiveDailyNewsletter;
        authValues.receiveWeeklyNewsletter = this.state.receiveWeeklyNewsletter;
        authValues.receiveRealTimeNotification = this.state.receiveRealTimeNotification;
        authValues.isDataSteward = this.state.isDataSteward;
        authValues.receiveRealTimeNotificationForNewPsqArqcSent = this.state.receiveRealTimeNotificationForNewPsqArqcSent;
        // Populate companyName using companyId if needed
        // If there were multiple companies get the name by id selected, otherwise, use company_name
        if (values.role === '4') {
            // If missing first/last name, then exit function
            if (!values || !values.first_name || !values.first_name.trim() === "" || !values.last_name || !values.last_name.trim() === "") {
                return;
            }

            authValues = Object.assign(
                {},
                authValues,
                {
                    companyName: values.companies && values.companies.length > 0 ?
                        values.companies.find(x => x.id == values.companyId).name : values.company_name,
                    firstName: values.first_name,
                    lastName: values.last_name,
                    guid: this.state.myEyUser ? this.state.myEyUser.guid : null

                }
            );

            if (authValues.companies) {
                delete authValues.companies;
            }
        }

        this.props.commonActions.beginTask();

        this.props.authActions.createAuthorization(authValues).then(() => {

            this.reloadAdminAuths(true);
            this.setState({
                auth: {
                    "userId": null,
                    "resource": authActions.resource.Client,
                    "role": "2",
                    "resourceId": this.props.clientId,
                    "id": null,
                    receiveDailyNewsletter: false,
                    receiveWeeklyNewsletter: false,
                    receiveRealTimeNotification: false,
                    receiveRealTimeNotificationForNewPsqArqcSent: false,
                    isDataSteward: false
                },
            });
            this.toggleShowAddClientAuthModal();
        }).catch((error) => {
            this.toggleShowAddClientAuthModal();
            this.reloadAdminAuths(true);
            var authStatusNotification = toast(
                (
                    <MotifToast position="right"
                        variant="error"
                        onClose={() => this.handleAuthStatusNotificationClose(authStatusNotification)}
                    >
                        <div>
                            <div className="row"><div className="ml-3 mr-2">
                                {error.message}
                            </div></div>
                        </div>
                    </MotifToast>
                )
                , {
                    autoClose: 5000,
                });
        });
        // If we just added a new External Checklist User, and they are a new My EY user,
        // then show the additional modal with My EY Onboarding information
        // NOTE: No longer using this additional modal
        // if (authValues.role === '4' && !authValues.status) {
        //     this.toggleShowMyEyOnboardingModal();
        // }

        this.props.commonActions.endTask();
    }


    handleEditButtonClick(formAuth) {
        this.handleDataStewardsForClientCount();
        for (let a of this.props.authorizations) {
            if (a.id === formAuth.id) {

                let authToUpdate = Object.assign({}, a);
                authToUpdate.role = a.role.toString();
                this.setState({ dataStewardValue: authToUpdate.isDataSteward });
                this.setState({ auth: authToUpdate, receiveDailyNewsletter: authToUpdate.receiveDailyNewsletter, receiveWeeklyNewsletter: authToUpdate.receiveWeeklyNewsletter, receiveRealTimeNotification: authToUpdate.receiveRealTimeNotification, receiveRealTimeNotificationForNewPsqArqcSent: authToUpdate.receiveRealTimeNotificationForNewPsqArqcSent, isDataSteward: authToUpdate.isDataSteward },
                    () => {
                        this.toggleShowEditClientAuthModal();
                    });
                break;
            }

        }
    }

    handleAuthStatusNotificationClose(authStatusNotification) {
        if (authStatusNotification && toast.isActive(authStatusNotification)) {
            toast.dismiss(authStatusNotification);
        }
    }

    handleEditClientAuthModalAction(values) {
        const dataStewardUserID = this.props.authorizations && this.props.authorizations.find(x => x.isDataSteward)?.userId || null;
        if (this.props.authorizations && this.props.authorizations.filter(x => x.isDataSteward).length === 1) {
            if ((!this.state.isDataSteward || values.role !== "1") && dataStewardUserID === this.state.auth.userId) {
                this.toggleShowEditClientAuthModal();
                this.toggleShowMinimumDataStewardWarningModal();
                this.setState({
                    auth: {
                        "userId": null,
                        "resource": authActions.resource.Client,
                        "role": "2",
                        "resourceId": this.props.clientId,
                        "id": null,
                        receiveDailyNewsletter: false,
                        receiveWeeklyNewsletter: false,
                        receiveRealTimeNotificationForNewPsqArqcSent: false,
                        isDataSteward: true
                    }
                });
                return;
            }
        }
        const updatedValues = {
            ...values,
            receiveDailyNewsletter: this.state.receiveDailyNewsletter,
            receiveWeeklyNewsletter: this.state.receiveWeeklyNewsletter,
            receiveRealTimeNotification: this.state.receiveRealTimeNotification,
            receiveRealTimeNotificationForNewPsqArqcSent: this.state.receiveRealTimeNotificationForNewPsqArqcSent,
            isDataSteward: values.role === "1" ? this.state.isDataSteward : false,
        };

        this.setState({ auth: updatedValues }, () => {
            this.props.authActions.updateAuthorization(this.state.auth).then(() => {
                this.reloadAdminAuths(true);
                this.setState({
                    auth: {
                        "userId": null,
                        "resource": authActions.resource.Client,
                        "role": "2",
                        "resourceId": this.props.clientId,
                        "id": null,
                        receiveDailyNewsletter: false,
                        receiveWeeklyNewsletter: false,
                        receiveRealTimeNotificationForNewPsqArqcSent: false,
                        isDataSteward: false
                    }
                });
                this.toggleShowEditClientAuthModal();
            }).catch((error) => {
                this.toggleShowEditClientAuthModal();
                this.reloadAdminAuths(true);
                var authStatusNotification = toast(
                    (
                        <MotifToast position="right"
                            variant="error"
                            onClose={() => this.handleAuthStatusNotificationClose(authStatusNotification)}
                        >
                            <div>
                                <div className="row"><div className="ml-3 mr-2">
                                    {error.message}
                                </div></div>
                            </div>
                        </MotifToast>
                    )
                    , {
                        autoClose: 5000,
                    });
            });
        });
    }

    handleEditClientAuthModalCancelAction() {
        this.props.commonActions.clearFormErrors("clientAuth");
        this.setState({
            auth: {
                "userId": null,
                "resource": authActions.resource.Client,
                "role": "2",
                "resourceId": this.props.clientId,
                "id": null,
                receiveDailyNewsletter: false,
                receiveWeeklyNewsletter: false,
                receiveRealTimeNotification: false,
                receiveRealTimeNotificationForNewPsqArqcSent: false,
                isDataSteward: false
            }
        }, () => this.toggleShowEditClientAuthModal());
    }

    handleAddClientAuthModalCancelAction() {
        this.props.commonActions.clearFormErrors("clientAuth");
        this.setState({
            auth: {
                "userId": null,
                "resource": authActions.resource.Client,
                "role": "2",
                "resourceId": this.props.clientId,
                "id": null,
                receiveDailyNewsletter: false,
                receiveWeeklyNewsletter: false,
                isDataSteward: false
            },
            addAuthMessageText: null,
            maxLimitMessageText: null,
            myEyUser: {}
        }, () => this.toggleShowAddClientAuthModal());

    }

    handleAddClientAuthModalValidateAction(valid) {
        if (valid) {
            // Check if user is already authorized for this Client
            for (let auth of this.props.authorizations) {
                if (this.props.clientAuthFormValues.userId.toLowerCase() === auth.userId.toLowerCase()) {
                    this.setState({ addAuthMessageText: `'${this.props.clientAuthFormValues.userId}' is already an authorized user.` });
                    return;
                }
            }

            this.setState({ myEyUser: {}, validatingUser: true, myEyError: null, addAuthMessageText: null }, () => {
                authActions.getMyEyUserByEmail(this.props.clientAuthFormValues.userId).then((myEyUser) => {
                    // If the domain_status is INACTIVE, we will not be able to add this email domain to MyEY
                    if (myEyUser.domain_status === 'INACTIVE') {
                        this.setState({
                            validatingUser: false,
                            myEyError: 'Unable to verify the email domain of the user in My EY. Please contact system admin for assistance.'
                        });
                    } else {
                        this.setState({
                            validatingUser: false,
                            myEyUser: myEyUser,
                            auth: Object.assign({}, this.state.auth, {
                                userId: myEyUser.email,
                                role: '4',
                                first_name: myEyUser.first_name,
                                last_name: myEyUser.last_name,
                                company_name: myEyUser.company_name,
                                companies: myEyUser.companies,
                                companyId: myEyUser.companies && myEyUser.companies[0] ? myEyUser.companies[0].id : null,
                                applications: myEyUser.applications,
                                status: myEyUser.status
                            })
                        });
                    }
                }).catch((err) => {
                    this.setState({
                        validatingUser: false,
                        myEyError: 'Error validating My EY User.'
                    })
                });
            });
        }
    }

    reloadAdminAuths(reloadAdminAuths) {
        if (reloadAdminAuths) {
            this.props.authorizationActions.fetchAuthorizations(null, 2, this.props.clientId, null);
        }
    }

    toggleShowAddClientAuthModal() {
        if (!this.state.showAddClientAuthModal) {
            this.setState({
                auth: {
                    "userId": null,
                    "resource": authActions.resource.Client,
                    "role": "2",
                    "resourceId": this.props.clientId,
                    "id": null,
                    "myEyError": null,
                    receiveDailyNewsletter: false,
                    receiveWeeklyNewsletter: false,
                    receiveRealTimeNotification: false,
                    receiveRealTimeNotificationForNewPsqArqcSent: false,
                    isDataSteward: false
                },
                addAuthMessageText: null,
                maxLimitMessageText: null,
                myEyUser: {},
                receiveDailyNewsletter: false,
                receiveWeeklyNewsletter: false,
                receiveRealTimeNotification: true,
                receiveRealTimeNotificationForNewPsqArqcSent: false,
                myEyError: null,
                isDataSteward: false
            },
                this.setState({ showAddClientAuthModal: !this.state.showAddClientAuthModal }));
        } else {
            this.setState({ showAddClientAuthModal: !this.state.showAddClientAuthModal }, () => store.dispatch(reset('clientAuth')));
        }
    }

    toggleShowMyEyOnboardingModal() {
        this.setState({ showMyEyOnboardingModal: !this.state.showMyEyOnboardingModal });
    }

    toggleShowEditClientAuthModal() {
        this.setState({ showEditClientAuthModal: !this.state.showEditClientAuthModal });
    }

    setFilter() {
        if (this.state.clientUserSearchText === "") {
            this.setState({ filteredClientAuths: Object.assign([], this.props.authorizations) });
        } else {
            this.setState({ filteredClientAuths: this.props.authorizations.filter((auth) => auth.userId.toLowerCase().includes(this.state.clientUserSearchText.toLowerCase())) });
        }
    }

    onConfirmDelete() {
        if (!this.state.currentUserIsSysAdmin && this.deletedUserID?.toLowerCase() == this.state.currentUserId?.toLowerCase()) {
            this.props.router.navigate('/');
            this.setState({ reloadClients: true });
        }
        if (this.state.confirmAction) {
            this.state.confirmAction();
            this.setState({ confirmAction: null });
        }
    }

    handleCancelDelete() {
        this.setState({ confirmAction: null });
    }

    handleToggleActiveFlag(e, clientId, clientName, clientLegalName, isDisabled) {
        const clientData = {
            "id": clientId,
            "name": clientName,
            "legalName": clientLegalName
        };
        if (e.target.checked) {
            // Only do confirmation if not a NEW CLIENT
            if (this.props.clientId !== 0 && !isDisabled) {
                this.setState({ clientData: clientData }, this.toggleShowReactivateClientModal());
            }
            else {
                this.handleReactivateClient();
            }

            return;
        }

        this.handleDeactivateClient();
    }

    handleToggleAuditFlag(e, isDisabled) {
        if(!isDisabled){
            if(this.props.clientId>0){
            this.props.clientActions.checkIfClientCanSwitchType(this.props.clientId)
                .then((responseData) => {
                    this.setState({ canSwitchType: responseData }, () => {
                        if (this.state.canSwitchType) {
                            this.setState({ showClientSwitchingMessageModal: true });
                        } else {
                            this.setState({ showClientSwitchingErrorMessageModal: true });
                        }                  
                    });
                });
        }
        else{
            this.setState({ isAudit: !this.state.isAudit });
        }
     }
    }

    handleClientSwitching() {
        this.setState({ showClientSwitchingMessageModal: false });
        const requestData = {
            "clientId": this.props.clientId,
            "isAudit": !this.state.isAudit,
        };
        this.props.clientActions.switchClientType(requestData).then(() => {
            this.setState({ isAudit: !this.state.isAudit });
            this.props.actions.loadClient(this.props.clientId);
        });
    }

    handleReactivateClient() {
        this.setState({ isActive: true, showReactivateClientModal: false });
    }

    handleDeactivateClient() {
        if (!this.state.isDisabledValue) {
            if (this.props.clientId > 0) {
                this.props.dataPurgeActions.getdataPurgeApprovedStatus(this.props.clientId).then((responseData) => {
                    if (responseData && responseData.returnCode === 0) {
                        this.setState({ showDeactivateErrorMessageModal: true });
                    } else {
                        this.setState({ isActive: false, showReactivateClientModal: false });
                    }
                });
            } else {
                this.setState({ isActive: false, showReactivateClientModal: false });
            }
        }
    }

    toggleShowReactivateClientModal(periodId) {
        this.setState({ showReactivateClientModal: !this.state.showReactivateClientModal });
    }

    toggleIsActive() {
        this.setState({ isActive: !this.state.isActive });
    }

    redirectToPropertiesPage(reitId, reportPeriodId) {

        this.props.router.navigate(`/client/${this.props.clientId}/reit/${reitId}/period/${reportPeriodId}/properties`);
    }

    validateDomain(value) {
        return authorizationDomainValidator.allowedDomain(value, this.state.allowedDomains);
    }

    validateDomainWithRole(value, allValues) {
        return authorizationDomainValidator.allowedDomain(value, this.state.allowedDomains, allValues);
    }

    handleDailyNewsletter(e) {
        if (e && e.target) {
            this.setState({ receiveDailyNewsletter: e.target.checked });
        }
    }
    handleWeeklyNewsletter(e) {
        if (e && e.target) {
            this.setState({ receiveWeeklyNewsletter: e.target.checked });
        }
    }
    handleDataSteward(e) {
        if (e && e.target) {
            this.setState({ isDataSteward: e.target.checked });
        }
    }

    handleRealTimeNotification(e) {
        if (e && e.target) {
            this.setState({ receiveRealTimeNotification: e.target.checked });
        }
    }

    handleOnRoleTypeChange(e) {
        if (e && e.target && e.target.value == "4") {
            this.setState({ receiveRealTimeNotification: false });
            this.setState({ receiveRealTimeNotificationForNewPsqArqcSent: true });
        }
        else {
            this.setState({ receiveRealTimeNotification: true });
            this.setState({ receiveRealTimeNotificationForNewPsqArqcSent: false });
        }
    }

    handleInternalReviewNotification(e) {
        if (e && e.target) {
            this.setState({ receiveRealTimeNotificationForNewPsqArqcSent: e.target.checked });
        }
    }

    toggleShowMinimumDataStewardWarningModal = () => {
        this.setState({
            showMinimumDataStewardWarningModal: !this.state.showMinimumDataStewardWarningModal,
        });

    };

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        const HeaderWrapper = ({ children }) => (
            <div
                style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                }}
            >
                {children}
            </div>
        );
        const authModalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '300px',
                height: '160px'
            }
        };

        const myEyModalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '600px',
                height: '320px'
            }
        };
        return (
            <div>
                {(this.props.client ? <h2>Client Settings</h2> : <h2>Create New Client</h2>)}
                <EditClientComponent
                    client={this.props.client}
                    reits={this.props.reits}
                    lookupTypes={this.props.lookupTypes}
                    showReitForm={this.state.showReitForm}
                    handleCloseReitForm={this.handleCloseReitForm}
                    handleAddReitClick={this.showReitForm}
                    handleEditReitClick={this.handleEditReitClick}
                    handleDeleteReitClick={this.handleDeleteReitClick}
                    handleClientSubmit={this.handleClientSubmit}
                    showSuccess={this.props.showSuccess && !this.state.hideSuccessMessage}
                    showReitSuccess={this.state.showReitSuccess && !this.state.hideReitSuccessMessage}
                    clientFormPurpose={this.state.clientFormPurpose}
                    reitFormPurpose={this.state.reitFormPurpose}
                    selectedReit={this.state.selectedReit}
                    handleReitSubmit={this.handleReitSubmit}
                    auths={this.state.filteredClientAuths}
                    handleSearchChange={this.handleSearchChange}
                    handleClearSearchText={this.handleClearSearchText}
                    clientUserSearchText={this.state.clientUserSearchText}
                    handleEditButtonClick={this.handleEditButtonClick}
                    handleDeleteButtonClick={this.handleDeleteButtonClick}
                    handleNewButtonClick={this.handleNewButtonClick}
                    showAddClientAuthModal={this.state.showAddClientAuthModal}
                    handleAddClientAuthModalAction={this.handleAddClientAuthModalAction}
                    auth={this.state.auth}
                    toggleShowAddClientAuthModal={this.handleAddClientAuthModalCancelAction}
                    currentUserId={this.state.currentUserId}
                    showEditClientAuthModal={this.state.showEditClientAuthModal}
                    handleEditClientAuthModalAction={this.handleEditClientAuthModalAction}
                    toggleShowEditClientAuthModal={this.handleEditClientAuthModalCancelAction}
                    currentUserIsSysAdmin={this.state.currentUserIsSysAdmin}
                    addAuthMessageText={this.state.addAuthMessageText}
                    maxLimitMessageText={this.state.maxLimitMessageText}
                    handleDismissErrorMessage={this.handleDismissErrorMessage}
                    handleDismissReitErrorMessage={this.handleDismissReitErrorMessage}
                    handleDismissSuccessMessage={this.handleDismissSuccessMessage}
                    handleDismissReitSuccessMessage={this.handleDismissReitSuccessMessage}
                    handleToggleActiveFlag={this.handleToggleActiveFlag}
                    isActive={this.state.isActive}
                    handleToggleAuditFlag={this.handleToggleAuditFlag}
                    isAudit={this.state.isAudit}
                    numberOfEngagementAdmins={this.state.numberOfEngagementAdmins}
                    numberOfDataStewards={this.state.numberOfDataStewards}
                    handleEditClientCancel={this.handleEditClientCancel}
                    validateDomain={this.validateDomain}
                    validateDomainWithRole={this.validateDomainWithRole}
                    clientAuthFormValues={this.props.clientAuthFormValues}
                    handleAddClientAuthModalValidateAction={this.handleAddClientAuthModalValidateAction}
                    validatingUser={this.state.validatingUser}
                    myEyError={this.state.myEyError}
                    handleDailyNewsletter={this.handleDailyNewsletter}
                    receiveDailyNewsletter={this.state && this.state.receiveDailyNewsletter || false}
                    handleWeeklyNewsletter={this.handleWeeklyNewsletter}
                    receiveWeeklyNewsletter={this.state && this.state.receiveWeeklyNewsletter || false}
                    receiveRealTimeNotification={this.state && this.state.receiveRealTimeNotification || false}
                    receiveRealTimeNotificationForNewPsqArqcSent={this.state && this.state.receiveRealTimeNotificationForNewPsqArqcSent || false}
                    handleRealTimeNotification={this.handleRealTimeNotification}
                    handleInternalReviewNotification={this.handleInternalReviewNotification}
                    handleOnRoleTypeChange={this.handleOnRoleTypeChange}
                    isDataSteward={this.state && this.state.isDataSteward || false}
                    handleDataSteward={this.handleDataSteward}
                    dataStewardCheckboxDisplay={this.state.dataStewardCheckboxDisplay}
                    showDSError={this.state.showDSError}
                    dataStewardValue={this.state.dataStewardValue}
                />
                <DeleteModal
                    showDeleteModal={this.state.confirmAction !== null}
                    confirmationMessage="Are you sure?" size="sm"
                    handleDelete={this.onConfirmDelete}
                    toggleshowDeleteModal={this.handleCancelDelete}
                    closeTitle="close authorized user delete modal"
                    headerTitle="Delete Authorized User"
                />
                <ReactivateClientModal
                    showReactivateClientModal={this.state.showReactivateClientModal}
                    toggleShowReactivateClientModal={this.toggleShowReactivateClientModal}
                    handleReactivateClient={this.handleReactivateClient}
                    handleCancel={this.handleDeactivateClient}
                    clientData={this.state.clientData} />
                <DeactivateClientDisplayErrorMessageModal
                    showDeactivateErrorMessageModal={this.state.showDeactivateErrorMessageModal}
                    handleCancel={this.toggleShowDeactivateClientModal}
                    confirmationMessage={Constants.deactivateClientConfirmationMessage}
                    headerTitle="Inactivate Client"
                />
                <ClientTypeSwitchingConfirmationMessageModal
                    showClientSwitchingMessageModal={this.state.showClientSwitchingMessageModal}
                    handleCancel={this.toggleShowClientSwitchingModal}
                    confirmationMessage={Constants.clientSwitchingConfirmationMessage}
                    headerTitle="Confirm Client Type Change"
                    handleClientSwitching={this.handleClientSwitching}
                />
                <ClientTypeSwitchingErrorMessageModal
                    showClientSwitchingErrorMessageModal={this.state.showClientSwitchingErrorMessageModal}
                    handleCancel={this.toggleShowClientSwitchingErrorModal}
                    confirmationMessage={Constants.clientSwitchingErrorMessage}
                    headerTitle="Client Type Change"
                />
                <MotifModal variant="alt" show={this.state.showMyEyOnboardingModal} data-testid="DeleteChecklistUserModal">
                    <MotifModalHeader>
                        <HeaderWrapper>
                            <MotifIcon style={{ color: "#F95D54" }} iconFunction={IconoirWarningCircle} size='24' fill='none' />
                            <span className="ml-2">Delete Checklist User</span>
                        </HeaderWrapper>
                    </MotifModalHeader>
                    <MotifModalBody>
                        <p>The information was successfully submitted.</p>
                        <p>REITSuite will take care of the onboarding to My EY,
                            just let the external user know that they will receive an
                            email invitation to join My EY.</p>
                        <p>If you experience any issues, please contact us at <a href="mailto:reitsuite@ey.com">reitsuite@ey.com</a>.</p>
                        <br />
                    </MotifModalBody>
                    <MotifModalFooter>
                        <MotifButton variant="primary" type="button" onClick={this.toggleShowMyEyOnboardingModal}>
                            Ok
                        </MotifButton>
                    </MotifModalFooter>
                </MotifModal>

                <DataStewardAssignmentRequiredModal
                    showEngagementAdminForDataStewardAssignmentModal={this.state.showMinimumDataStewardWarningModal}
                    toggleShowEngagementAdminForDataStewardAssignmentModal={this.toggleShowMinimumDataStewardWarningModal}
                    dataStewardAssignmentWarningHeader={Constants.dataStewardDeletionWarningHeader}
                    dataStewardAssignmentWarningBody={Constants.dataStewardDeletionWarningBody}
                />
                {/* <ToastContainer
                    ref={this.toastSystem}
                    position="bottom-right"
                    closeOnClick={false}
                    pauseOnHover={false}
                    draggable={false}
                    closeButton={false}
                    hideProgressBar={true}
                    transition={Slide}
                /> */}
            </div>
        );
    }
}

EditClientPage.propTypes = {
    authorizations: PropTypes.array,
    currentUserAuthorizations: PropTypes.array,
    clientId: PropTypes.number,
    client: PropTypes.object,
    reitId: PropTypes.number,
    reits: PropTypes.array,
    propertyId: PropTypes.number,
    showSuccess: PropTypes.string,
    reportPeriodId: PropTypes.number,
    actions: PropTypes.object,
    reitActions: PropTypes.object,
    dataPurgeActions: PropTypes.object,
    propertyActions: PropTypes.object,
    authorizationActions: PropTypes.object,
    currentUserId: PropTypes.string.isRequired,
    currentUserIsSysAdmin: PropTypes.bool.isRequired,
    commonActions: PropTypes.object.isRequired,
    clientAuthFormValues: PropTypes.object,
    dataPurgeConfigurationData: PropTypes.array,
    deletedUserID: PropTypes.string,
    canSwitchType: PropTypes.bool,

};

EditClientPage.contextTypes = {
    router: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    let cid = Number.parseInt(ownProps.router.params.clientId);
    if (isNaN(cid)) {
        cid = 0;
    }

    let periodId = Number.parseInt(ownProps.router.params.periodId);
    if (isNaN(periodId)) {
        periodId = 0;
    }
    const authorizations = state.authorizations;
    const currentUserAuthorizations = state.currentUserAuthorizations;
    const searchParams = new URLSearchParams(ownProps.router.location.search);
    const showSuccess = searchParams.get('showSuccess');
    return {
        clientId: cid,
        reportPeriodId: periodId,
        client: state.client,
        reits: state.reits,
        lookupTypes: state.lookupTypes,
        showSuccess: showSuccess,
        authorizations: authorizations,
        currentUserAuthorizations: currentUserAuthorizations,
        dataPurgeConfigurationData: state.dataPurgeConfigurationData,
        currentUserId: state.authentication.currentUser,
        currentUserIsSysAdmin: Array.isArray(state.currentUserAuthorizations) && (authActions.isSystemAdministrator(state.currentUserAuthorizations)),
        clientAuthFormValues: getFormValues('clientAuth')(state),
        canSwitchType: state.canSwitchType
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(clientActions, dispatch),
        reitActions: bindActionCreators(reitActions, dispatch),
        authorizationActions: bindActionCreators(authActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch),
        dataPurgeActions: bindActionCreators(dataPurgeActions, dispatch),
        clientActions: bindActionCreators(clientActions, dispatch),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditClientPage));