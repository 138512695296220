import React, { Component } from 'react';
import { MotifErrorMessage, MotifTextLink } from "@ey-xd/motif-react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { withRouter } from "../../common/withRouter";
import * as authActions from "../../actions/authActions";
import PropTypes from 'prop-types';

class MissingDeletionApprovalContainer extends Component {
    constructor(props) {
        super(props);
      }

    render() {        
        return (
            <div>
                <h5 style={{ fontWeight: 'bold' }}>
                    <MotifErrorMessage>
                        Access Restricted
                    </MotifErrorMessage>
                </h5>
                <br />
                <p>
                    In accordance with the REITSuite Data Retention Policy, we have not received your approval or exception for the records scheduled for deletion this year. As a result, your access to this client site has been restricted. To restore access, please have your Data Steward provide the necessary approvals or obtain an exception. For more information on the policy and detailed instructions on how to grant approvals or obtain exceptions, please refer to the <Link to="/helpguide" target="_blank" rel="noopener noreferrer"><MotifTextLink href="/helpguide" target="_blank" variant='alt'>REITSuite help guide</MotifTextLink></Link>.
                </p>
                <br />
                <h5>Appoint Data Stewards (if needed)</h5>
                <ul style={{ listStyleType: 'none' }}>
                    {authActions.isSystemAdministrator(this.props.currentUserAuthorizations) || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.clientId) 
                    ?
                        <li style={{ marginBottom: '0.2em' }}>
                            - You are required to appoint at least one, but no more than two Data Stewards. Assign them on the <MotifTextLink href={`/client/${this.props.client.id}/dataStewardRoleManagement`} target="_self" rel="noopener noreferrer" variant='alt'>Add Data Steward</MotifTextLink> page.
                        </li>
                    :
                        <li style={{marginBottom:'0.2em'}}>
                        - You are required to appoint at least one, but no more than two Data Stewards. Assign them on the Add Data Steward page.
                        </li>
                    }
                    <li style={{marginBottom:'0.2em'}}>
                        - Please note only Engagement Administrators can assign Data Stewards.
                    </li>
                </ul>
                 <br />
                <h5>Export Data (if needed)</h5>
                <ul style={{ listStyleType: 'none' }}>
                <li style={{marginBottom:'0.2em'}}>
                    - Export the data needed from the <MotifTextLink href={`/client/${this.props.client.id}/bulkReportManagement`} target="_self" rel="noopener noreferrer" variant='alt'>Bulk Report Management</MotifTextLink> page. Transfer it to the designated systems in accordance with the firm’s policy.
                </li>
                </ul>
                <br />
                <h5>Provide Approvals</h5>
                <ul style={{ listStyleType: 'none' }}>
                    {authActions.isSystemAdministrator(this.props.currentUserAuthorizations) || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.clientId) 
                    ?
                        <li style={{marginBottom:'0.2em'}}>
                            - Data Stewards can provide approvals on the <MotifTextLink href={`/client/${this.props.client.id}/dataRetentionManagement`} target="_self" rel="noopener noreferrer" variant='alt'>Data Retention Management</MotifTextLink> page. Approve all report periods with a lapsed Deletion Approval Due Date. However, if you need to seek an exception, please email the REITSuite team at <Link to="mailto:reitsuite@ey.com" target="_blank" rel="noopener noreferrer"><MotifTextLink href="mailto:reitsuite@ey.com" target="_blank" variant='alt'>reitsuite@ey.com</MotifTextLink></Link>. Please note you will need to submit a business case to Tax Quality and Independence for exception.
                        </li>
                    :
                        <li style={{marginBottom:'0.2em'}}>
                            - Data Stewards can provide approvals on the Data Retention Management page. Approve all report periods with a lapsed Deletion Approval Due Date. However, if you need to seek an exception, please email the REITSuite team at <Link to="mailto:reitsuite@ey.com" target="_blank" rel="noopener noreferrer"><MotifTextLink href="mailto:reitsuite@ey.com" target="_blank" variant='alt'>reitsuite@ey.com</MotifTextLink></Link>. Please note you will need to submit a business case to Tax Quality and Independence for exception.
                        </li>
                    }
                    <li style={{marginBottom:'0.2em'}}>
                        - Please note only Data Stewards can approve data deletion requests.
                    </li>
                </ul>
                <br />
                <p>Note: Once all appropriate report periods are approved (or have an exception), full access to the client site will be restored.</p>
            </div>
        );
    }
}

MissingDeletionApprovalContainer.propTypes = {    
    currentUserAuthorizations: PropTypes.array,
    clientId: PropTypes.number,
    client: PropTypes.object,
    authActions: PropTypes.object,
    router: PropTypes.object,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
const mapStateToProps = (state, ownProps) => {
    let cid = Number.parseInt(ownProps.router.params.clientId);
    if (isNaN(cid)) {
        cid = 0;
    }
    const currentUserAuthorizations = state.currentUserAuthorizations;
    return {
        clientId: cid,
        client: state.client,
        currentUserAuthorizations: currentUserAuthorizations,
    };
};

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MissingDeletionApprovalContainer));