import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Routes, createBrowserRouter, RouterProvider, } from 'react-router-dom';
import {
  appInsights,
  initUserData,
  setUserId,
  clearUserId,
} from '@ey/react-telemetry-library';
import withAppInsights from '@ey/react-telemetry-library';
import App from './App';
import ExternalApp from './ExternalApp';
import PermExternalApp from './PermExternalApp';
import AssetTestPage from '../containers/reits/AssetTestPage';
import NotFoundPage from './NotFoundPage';
import LoginPage from '../containers/LoginPage';
import ClientsPage from '../containers/clients/ClientsPage';
import EditClientPage from '../containers/clients/EditClientPage';
import ClientPage from '../containers/clients/ClientPage';
import ClientsExternalPage from '../containers/clients/ClientsExternalPage';
import ChecklistExternalContainer from '../containers/checklistDeliveryCalendar/ChecklistExternalContainer';
import PSQChecklistExternalContainer from '../containers/checklistDeliveryCalendar/PSQChecklistExternalContainer';
import ARQCChecklistExternalContainer from '../containers/checklistDeliveryCalendar/ARQCChecklistExternalContainer';
import ClientLoader from '../containers/clients/ClientLoader';
import ReitLoader from '../containers/reits/ReitLoader';
import ReitPage from '../containers/reits/ReitPage';
import ReportPeriodLoader from '../containers/reits/ReportPeriodLoader';
import TrialBalanceUploadPage from '../containers/tb/TrialBalanceUploadPage';
import ReportPage from '../containers/reports/ReportPage';
import EditTrsContainer from '../containers/reits/EditTrsContainer';
import EditSecuritiesSnapshotContainer from '../containers/reits/EditSecuritiesSnapshotContainer';
import PropertiesContainer from '../containers/reits/PropertiesContainer';
import IncomeTestPage from '../containers/reits/IncomeTestPage';
import ArqcChecklistPage from '../containers/reits/ARQCChecklistContainer';
import ArqcChecklistRecord from '../containers/reits/ArqcChecklistRecord';
import DistributionTestContainer from '../containers/reits/DistributionTestContainer';
import PsqChecklistPage from '../containers/reits/PSQChecklistContainer';
import PsqChecklistRecord from '../containers/reits/PsqChecklistRecord';
import ExternalArqcChecklistPage from '../containers/reits/ExternalArqcChecklistPage';
import ReitsExternalPage from '../containers/reits/ReitsExternalPage';
import ChecklistsExternalPage from '../containers/reits/ChecklistsExternalPage';
import ExternalPsqChecklistPage from '../containers/reits/ExternalPsqChecklistPage';
import CallbackPage from '../containers/CallbackPage';
import FileManagementPage from '../containers/fileManagement/FileManagementPage';
import ReitFileManagementPage from '../containers/fileManagement/ReitFileManagementPage';
import AdminAuthPage from '../containers/auth/AdminAuthPage';
import UnauthorizedUserContainer from '../containers/unauthorize/UnauthorizedUserContainer';
import ExternalUnauthorizedUserContainer from '../containers/unauthorize/ExternalUnauthorizedUserContainer';
// import ContactUsersPage from "../containers/contactUsers/ContactUsersPage";
import MappingsPage from '../containers/tb/MappingsPage';
import ScheduleChecklistContainer from '../containers/checklistDeliveryCalendar/ScheduleChecklistContainer';
import ManageChecklistContainer from '../containers/checklistDeliveryCalendar/ManageChecklistContainer';
import CustomChecklistTemplateContainer from '../containers/customChecklistQuestions/CustomChecklistTemplateContainer';
import CustomFdrTemplateContainer from '../containers/customFdrTemplate/CustomFdrTemplateContainer';
import ChartOfAccountsManagerContainer from '../containers/chartOfAccountsManager/ChartOfAccountsManagerContainer';
import ChartAssignmentContainer from '../containers/chartOfAccountsManager/ChartAssignmentContainer';
import BulkReportManagementContainer from '../containers/bulkReportManagement/BulkReportManagementContainer';
import DefaultFdrTemplatesContainer from '../containers/customFdrTemplate/DefaultFdrTemplatesContainer';
import PsqCustomChecklistTemplateContainer from '../containers/psqCustomChecklistTemplate/PsqCustomChecklistTemplateContainer';
import PsqChecklistTemplatePreview from '../containers/psqCustomChecklistTemplate/PsqChecklistTemplatePreview';
import BulkProcessManagementContainer from '../containers/bulkProcessManagement/BulkProcessManagementContainer';
import BulkRollForwardContainer from '../containers/bulkRollForward/BulkRollForwardContainer';
import LogoffPage from '../containers/LogoffPage';
import * as stringHelpers from '../scripts/stringHelpers';
import * as authenticationActions from '../actions/authenticationActions';
import CustomRouterProvider from '../common/CustomRouterProvider';
import RoleGuard from '../common/RoleGuard';

//import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
//import ConditionalMsalProvider from '../common/ConditionalMsalProvider';


import DataRetentionManagementContainer from '../containers/dataRetentionManagement/DataRetentionManagementContainer';
import DataRetentionManagementPolicy from './dataRetentionManagement/dataRetentionManagementPolicy';
import DataStewardRoleManagementContainer from '../containers/dataStewardRoleManagement/dataStewardRoleManagementContainer';
import DataDeletionDiagnosticPage from '../containers/dataDeletionDiagnostic/DataDeletionDiagnosticPage';
import ExternalUserDataVisibilityContainer from '../containers/externalUserDataVisibility/ExternalUserDataVisibilityContainer';
import MissingDeletionApprovalContainer from '../containers/dataRetentionManagement/MissingDeletionApprovalContainer';
/**
 * The Root component renders the Router and connects everything to the store
 * @extends {Component}
 */
class Root extends Component {
  /**
   * Creates a new Root
   * @constructor
   * @param {Object} props The component properties
   * @param {Object} context The component context
   */
  constructor(props, context) {
    super(props, context);

    this.lastPathname = '';
    this.userType = '';
    this.userEmail = '';

    // Before use, you must provide the library REACT_APP_INSTRUMENTATIONKEY on file .env
    if (process.env.REACT_APP_INSTRUMENTATIONKEY !== 'local') {
      // Mandatory function for Usage Statistics
      initUserData();

      // Listen for page changes
      props.history.listen((location) => {
        const pathname = location.pathname;

        // Sometimes this history.listen event fires twice, so ensure we don't fire twice if path hasn't changed
        if (pathname !== this.lastPathname) {
          this.lastPathname = pathname;

          // Are we on a client page? (internal or external)
          const isClientInPath =
            pathname.includes('/client/') || pathname.includes('/ext/reits/');

          // Retry up to 5 times because Client api may have not responded fast enough after listen event fires
          let retryCount = 5;
          let trackPageViewTimeout;

          const attemptTrackPageView = () => {
            clearTimeout(trackPageViewTimeout);
            retryCount--;

            // Check if we are on a Client page, and optionally add clientName to metrics
            const splitPath = pathname.split('/');
            const clientIdInPath =
              this.props.authenticationScope == 'external'
                ? splitPath[3]
                : splitPath[2];
            const clientIdInProps =
              this.props.client && this.props.client.id
                ? this.props.client.id.toString()
                : null;

            if (
              ((!isClientInPath || clientIdInPath == clientIdInProps) &&
                this.userEmail) ||
              retryCount == 0
            ) {
              const appInsightData = {
                name: location.pathname,
                properties: {
                  'user-type': this.userType,
                  'user-guid': stringHelpers.stringToHash(this.userEmail),
                  'user-email': this.userEmail,
                  'app-id': 'EY REITSuite',
                },
              };

              // Optionally add client-name and engagement-id if on a Client page
              if (isClientInPath && clientIdInProps) {
                appInsightData.properties['client-name'] =
                  this.props.client.name;
                appInsightData.properties['engagement-id'] =
                  this.props.client.engagementNumber;
              }

              // Log page change to App Insights
              // (ignore "EY REITSuite")
              if (location.pathname !== 'EY REITSuite') {
                appInsights.trackPageView(appInsightData);
              }
            } else {
              // Retry
              trackPageViewTimeout = setTimeout(
                () => attemptTrackPageView(),
                1000,
              );
            }
          };

          // Trigger the function that will retry 5 times to ensure we have given enough time to fetch Client Info
          attemptTrackPageView();
        }
      });
    }

    this.getClientName = this.getClientName.bind(this);
    this.getReitName = this.getReitName.bind(this);
    this.getPeriodName = this.getPeriodName.bind(this);
    this.getRoutes = this.getRoutes.bind(this);
    this.getRouter = this.getRouter.bind(this);
  }


  componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      const userEmail = this.props.currentUser;
      this.userEmail = userEmail;

      // Store the email domain in userType
      if (userEmail.includes('@')) {
        this.userType = userEmail.split('@')[1];
      }

      if (process.env.REACT_APP_INSTRUMENTATIONKEY !== 'local') {
        // Set the User ID (email address) of the authenticated user
        setUserId(userEmail);
      }
    }
  }

  getClientName() {
    return (
      (this.props.store.getState().client &&
        this.props.store.getState().client.name) ||
      '...'
    );
  }

  getReitName() {
    return (
      (this.props.store.getState().reit &&
        this.props.store.getState().reit.reitName) ||
      '...'
    );
  }

  getPeriodName() {
    return (
      (this.props.store.getState().period &&
        this.props.store.getState().period.reportPeriodDescription) ||
      '...'
    );
  }

  getRoutes() {
    return [
      {
        path: '',
        element: <CustomRouterProvider />,
        children: [
          {
            path: "/external",
            element: <ExternalApp />,
            children: [
              {
                path: "arqc/:checklistId",
                element: <ExternalArqcChecklistPage />,
              },
              {
                path: "psq/:checklistId",
                element: <ExternalPsqChecklistPage />,
              },
              {
                path: "logoff",
                element: <LogoffPage />,
              },
            ],
          },
          {
            path: "/ext",
            element: <PermExternalApp />,
            name: "Dashboard",
            handle: {
              name: "Dashboard",
            },
            children: [
              {
                index: true,
                element: <ChecklistExternalContainer />,
              },
              {
                path: "psq",
                handle: {
                  name: "PSQ",
                },
                children: [
                  {
                    index: true,
                    element: <PSQChecklistExternalContainer />,
                  },
                  {
                    path: "psqRecord/:internalChecklistId",
                    element: <ExternalPsqChecklistPage />,
                    name: "PSQ Record",
                  },
                ],
              },
              {
                path: "arqc",
                handle: {
                  name: "ARQC",
                },
                children: [
                  {
                    index: true,
                    element: <ARQCChecklistExternalContainer />,
                  },
                  {
                    path: "arqcRecord/:internalChecklistId",
                    element: <ExternalArqcChecklistPage />,
                    handle: {
                      name: "ARQC Record",
                    },
                  },
                ],
              },
              {
                path: "unauthorized",
                element: <ExternalUnauthorizedUserContainer />,
                handle: {
                  name: "Access Denied",
                },
              },
            ],
          },
          {
            path: "/auth",
            element: <CallbackPage />,
          },
          {
            path: "/",
            element: <App />,
            handle: {
              name: "Dashboard",
            },
            children: [             
              {
                path: "login",
                element: <LoginPage />,
              },
              {
                path: "adminauth",
                handle: {
                  name: "System Administrators",
                },
                children: [
                  {
                    index: true,
                    element: <AdminAuthPage />,
                  },
                  {
                    path: "dataDeletionDiagnostic",
                    element: <DataDeletionDiagnosticPage />,
                    handle: {
                      name: "Data Deletion Diagnostic",
                    },
                  },
                  {
                    path: "externalUserDataVisibility",
                    element: <ExternalUserDataVisibilityContainer />,
                    handle: {
                      name: "External User Data Visibility",
                    },
                  },
                ],
              },

              {
                path: "unauthorized",
                element: <UnauthorizedUserContainer />,
                handle: {
                  name: "Access Denied",
                },
              },
              {
                index: true,
                element: <ClientsPage />,
              },
              {
                path: "clients",
                element: <ClientsPage />,
              },
              {
                path: "client/edit",
                element: <EditClientPage />,
                handle: {
                  name: "New Client",
                },
              },
              {
                path: "client/:clientId",
                element: <ClientLoader />,
                handle: {
                  name: "Client",
                },
                children: [
                  {
                    index: true,
                    element: <ClientPage />,
                  },
                  {
                    path: "edit",
                    element: (
                      <RoleGuard systemAdminFlag={true}
                      engagementAdminFlag={true}
                      engagementUserFlag={false}>
                        <EditClientPage />
                      </RoleGuard>
                    ),
                    handle: {
                      name: "Client Settings",
                    },
                  },
                  {
                    path: "checklistManagement",
                    element: <ScheduleChecklistContainer />,
                    handle: {
                      name: "Checklist Management",
                    },
                  },
                  {
                    path: "manageChecklist",
                    element: <ManageChecklistContainer />,
                    handle: {
                      name: "Manage Checklist",
                    },
                  },
                  {
                    path: "customPsqTemplate",
                    handle: {
                      name: "Custom PSQ Template",
                    },
                    children: [
                      {
                        index: true,
                        element: <CustomChecklistTemplateContainer />,
                      },
                      // {
                      //   path: "manageQuestions",
                      //   element: <CustomQuestionsPage />,
                      //   handle: {
                      //     name: "Manage Questions",
                      //   },
                      // },
                      {
                        path: "template",
                        element: <PsqCustomChecklistTemplateContainer />,
                        handle: {
                          name: "Create Template",
                        },
                      },
                      {
                        path: "template/:templateId",
                        element: <PsqCustomChecklistTemplateContainer />,
                        handle: {
                          name: "Edit Template",
                        },
                      },
                      {
                        path: "template/:templateId/preview",
                        element: <PsqChecklistTemplatePreview />,
                        handle: {
                          name: "Template Preview",
                        },
                      },
                    ],
                  },
                  {
                    path: "customFdrTemplate",
                    handle: {
                      name: "Custom FDR Template",
                    },
                    children: [
                      {
                        index: true,
                        element: <CustomFdrTemplateContainer />,
                      },
                      {
                        path: "assignReits",
                        element: <DefaultFdrTemplatesContainer />,
                        handle: {
                          name: "Assign Defaults",
                        },
                      },
                    ],
                  },
                  {
                    path: "bulkReportManagement",
                    element: <BulkReportManagementContainer />,
                    handle: {
                      name: "Bulk Report Management",
                    },
                  },
                  {
                    path: "bulkProcessManagement",
                    element: <BulkProcessManagementContainer />,
                    handle: {
                      name: "Bulk Process Management",
                    },
                  },
                  {
                    path: "bulkRollForward",
                    element: <BulkRollForwardContainer />,
                    handle: {
                      name: "Bulk Roll Forward",
                    },
                  },                  
                  {
                    path: "chartOfAccountsManager",
                    handle: {
                      name: "Chart of Accounts Manager",
                    },
                    children: [
                      {
                        index: true,
                        element: <ChartOfAccountsManagerContainer />,
                      },
                      {
                        path: "assignCharts",
                        element: <ChartAssignmentContainer />,
                        handle: {
                          name: "Chart Assignment",
                        },
                      },
                    ],
                  },
                  {
                    path: "reit/:reitId",
                    element: <ReitLoader />,
                    handle: {
                      name: "Reit",
                    },
                    children: [
                      {
                        index: true,
                        element: <ReitPage />,
                      },
                      {
                        path: "files",
                        element: <ReitFileManagementPage />,
                        handle: {
                          name: "File Management",
                        },
                      },
                      {
                        path: "period/:periodId",
                        element: <ReportPeriodLoader />,
                        handle: {
                          name: "Period",
                        },
                        children: [
                          {
                            path: "",
                            index: true,
                            element: <TrialBalanceUploadPage />,
                          },
                          {
                            path: "uploadtb/:tbType",
                            index: true,
                            element: <TrialBalanceUploadPage />,
                            handle: {
                              name: "TB Upload",
                            },
                          },
                          {
                            path: "report",
                            element: <ReportPage />,
                            handle: {
                              name: "Final Report",
                            },
                          },
                          {
                            path: "trs",
                            element: <EditTrsContainer />,
                            handle: {
                              name: "TRS",
                            },
                          },
                          {
                            path: "securitiesSnapshot",
                            element: <EditSecuritiesSnapshotContainer />,
                            handle: {
                              name: "Securities",
                            },
                          },
                          {
                            path: "properties",
                            element: <PropertiesContainer />,
                            handle: {
                              name: "Properties",
                            },
                          },
                          {
                            path: "mappings",
                            element: <MappingsPage />,
                            handle: {
                              name: "Client Account Mappings",
                            },
                          },
                          {
                            path: "assettest",
                            element: <AssetTestPage />,
                            handle: {
                              name: "Asset Test",
                            },
                          },
                          {
                            path: "incometest",
                            element: <IncomeTestPage />,
                            handle: {
                              name: "Income Test",
                            },
                          },
                          {
                            path: "distributiontest",
                            element: <DistributionTestContainer />,
                            handle: {
                              name: "Distribution Test",
                            },
                          },
                          {
                            path: "arqc",
                            handle: {
                              name: "ARQC",
                            },
                            children: [
                              {
                                index: true,
                                element: <ArqcChecklistPage />,
                              },
                              {
                                path: "arqcRecord/:arqcChecklistId",
                                element: <ArqcChecklistRecord />,
                                handle: {
                                  name: "ARQC Record",
                                },
                              },
                            ],
                          },
                          {
                            path: "psq",
                            handle: {
                              name: "PSQ",
                            },
                            children: [
                              {
                                index: true,
                                element: <PsqChecklistPage />,
                              },
                              {
                                path: "psqRecord/:psqChecklistId",
                                element: <PsqChecklistRecord />,
                                handle: {
                                  name: "PSQ Record",
                                },
                              },
                            ],
                          },                         
                          {
                            path: "files",
                            element: <FileManagementPage />,
                            handle: {
                              name: "File Management",
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "dataRetentionManagement",
                    element: <DataRetentionManagementContainer />,
                    handle: {                                       
                       name: "Data Retention Management",
                     },
                  }, 

                  {
                    path: "dataStewardRoleManagement",
                    element: (
                      <RoleGuard 
                        systemAdminFlag={true}
                        engagementAdminFlag={true}
                        engagementUserFlag={false}>                    
                        <DataStewardRoleManagementContainer />
                      </RoleGuard>
                            ),
                    handle: {                                       
                       name: "Add Data Steward",
                     },
                  }, 
                  {
                    path: "missingDeletionApproval",
                    element: (
                      <RoleGuard 
                        systemAdminFlag={true}
                        engagementAdminFlag={true}
                        engagementUserFlag={true}>                    
                        <MissingDeletionApprovalContainer />
                      </RoleGuard>
                            ),
                    handle: {                                       
                       name: "Access Restricted",
                     },
                  }, 
                ],
              },
              {
                path: "*",
                element: <NotFoundPage />,
              },
              {
                path: "/helpGuide",
                element: <DataRetentionManagementPolicy />,
                handle: {                                       
                   name: "Help Guide",
                 },
              },
            ],
          },
         
        ],
      },
    ];
  }

  getRouter() {
    return createBrowserRouter(this.getRoutes());
  }

  render() {
    const { store } = this.props;
    return (
      <Provider store={store}>
          <RouterProvider
            router={this.getRouter()}
            fallbackElement={<div>Loading...</div>}
          />
      </Provider>
    );
  }
}

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state) {
  return {
    authenticationScope: state.authenticationScope,
    client: state.client,
    currentUser: state.authentication.currentUser,
  };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch),
  };
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  client: PropTypes.object,
  user: PropTypes.object,
  authenticationScope: PropTypes.string,
  currentUser: PropTypes.string,
  authenticationActions: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
