import React from 'react';
import { MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,MotifIcon,MotifButton } from '@ey-xd/motif-react';
import { IconoirInfoCircle } from '@ey-xd/motif-icon';
import {  reduxForm } from "redux-form";
import { IconoirWarningCircle } from '@ey-xd/motif-icon';
import PropTypes from 'prop-types';

const ApprovalsCompletedModal = ({showApprovalsCompletedModal,toggleShowApprovalsCompletedModal,approvalsCompletedModalHeader,approvalsCompletedModalBody1,approvalsCompletedModalBody2, handleOK
}) => {

    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}
        >
            {children}
        </div>
    );

    const closeButtonProps = {
        title: approvalsCompletedModalHeader,
        ariaLabel: approvalsCompletedModalHeader,
        dataTestid: 'close'
    };
    return (
    <MotifModal variant="alt"  show={showApprovalsCompletedModal} onClose={() => toggleShowApprovalsCompletedModal()} data-testid="ApprovalOrExceptionCompleted">
    <MotifModalHeader closeButtonProps={closeButtonProps} >
            <HeaderWrapper>                
                <span className="ml-2 mt-1">{approvalsCompletedModalHeader}</span>
            </HeaderWrapper>
        </MotifModalHeader>
   <MotifModalBody>
       <span>{approvalsCompletedModalBody1}</span><br/><br/>       
       <span>{approvalsCompletedModalBody2}</span><br/><br/>
   </MotifModalBody>
   <MotifModalFooter>
           <MotifButton              
               variant="primary"
               className="right"
               onClick={() => handleOK()}
           >
               OK
           </MotifButton>
       </MotifModalFooter>
 </MotifModal>
    );
};
ApprovalsCompletedModal.propTypes = {
    showApprovalsCompletedModal: PropTypes.bool,
    toggleShowApprovalsCompletedModal: PropTypes.func,
    approvalsCompletedModalHeader: PropTypes.string,
    approvalsCompletedModalBody1: PropTypes.string,
    approvalsCompletedModalBody2: PropTypes.string,
    handleOK: PropTypes.func
};

export default reduxForm({ form: "ApprovalsCompletedForm", enableReinitialize: true })(ApprovalsCompletedModal);

