import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { MotifModal, MotifModalBody, MotifModalHeader } from "@ey-xd/motif-react";

import { addAttribute } from "../../common/Attribute";
import EditReitForm from '../reits/EditReitForm';

const CreateEditReitModal = ({
    isOpen,
    handleModalClose,
    reitFormPurpose,
    initialValues,
    handleSubmit,
    handleDismissErrorMessage,
    lookupTypes,
    isAudit,
}) => {

    useEffect(() => {
        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    let modalHeaderText = 'Create New REIT';
    if (reitFormPurpose === "EDIT") {
        modalHeaderText = 'Edit REIT';
    }

    return (
        <MotifModal show={isOpen} onClose={handleModalClose} variant="alt" size="xl" className="create-edit-motif-modal">
            <MotifModalHeader>
                {modalHeaderText}
            </MotifModalHeader>
            <EditReitForm onSubmit={handleSubmit} handleCloseForm={handleModalClose}
                initialValues={initialValues} reitFormPurpose={reitFormPurpose}
                handleDismissErrorMessage={handleDismissErrorMessage}
                lookupTypes={lookupTypes}
                isAudit={isAudit} />
        </MotifModal>
    );
}

CreateEditReitModal.propTypes = {
    isOpen: PropTypes.bool,
    handleModalClose: PropTypes.func,
    reitFormPurpose: PropTypes.string,
    initialValues: PropTypes.object,
    error: PropTypes.object,
    submitting: PropTypes.bool,
    handleReitSubmit: PropTypes.func,
    handleDismissReitErrorMessage: PropTypes.func,
    lookupTypes: PropTypes.object,
    isAudit: PropTypes.bool,
};

export default CreateEditReitModal;